import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Switch, Route, matchPath } from 'react-router-dom';
import styled from 'styled-components';
import { Toaster } from 'react-hot-toast';

import Header from 'components/App/Header';
import NotFound from 'components/NotFound';
import SideBar from './SideBar';
import UserSuggestions, {SpacerHeader} from './UserSuggestions';
import UserAvailable from './UserAvailable'
import Footer from 'components/App/Footer'

import Home from 'pages/Home';
import Profile from 'pages/Profile';
import Explore from 'pages/Explore';
import People from 'pages/People';
import Notifications from 'pages/Notifications';
import Post from 'pages/Post';
import Schedule from 'pages/Schedule';
import ScheduleDetails from 'pages/Schedule/ScheduleDetails';
import Messages from 'pages/Messages';
import Administration from 'pages/Administration';
import Room from 'pages/Room'
import SpeedNetworking from 'pages/SpeedNetworking';

import { useWindowSize } from 'hooks/useWindowSize';
import { useClickOutside } from 'hooks/useClickOutside';
import { HEADER_HEIGHT } from 'constants/Layout';

import i18n from 'i18n';

import * as Routes from 'routes';

import theme from 'theme';

import { useStore } from 'store';
import { SET_AUTH_USER } from 'store/auth';

const Root = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  position: relative;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);

  @media (min-width: ${(p) => p.theme.screen.md}) {
    width: ${(p) => p.theme.screen.md};
  }

  @media (min-width: ${(p) => parseInt(p.theme.screen.lg, 10) + 20 + 'px'}) {
    width: ${(p) => p.theme.screen.lg};
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * Main layout of the app, when user is authenticated
 */
const AppLayout = ({ location, authUser }) => {
  const [{ auth }, dispatch] = useStore();

  const windowSize = useWindowSize();
  const isDesktop = windowSize.width >= parseInt(theme.screen.md, 10);
  const [isSideBarOpen, setIsSidebarOpen] = useState(isDesktop);

  const sideBarRef = useRef('');

  useEffect(() => {
    try {
      const persistedLocale = window.localStorage.getItem('locale');
      if (!!persistedLocale) {
        i18n.changeLanguage(persistedLocale);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: SET_AUTH_USER, payload: authUser });
  }, [dispatch, authUser]);

  useClickOutside(sideBarRef, () => {
    if (!isDesktop && isSideBarOpen) {
      setIsSidebarOpen(false);
    }
  });

  useEffect(() => {
    setIsSidebarOpen(isDesktop);
  }, [isDesktop]);

  useEffect(() => {
    return () => {
      if (!isDesktop) {
        setIsSidebarOpen(false);
      }
    };
  }, [location.pathname, isDesktop]);

  if (!auth.user) return null;

  const hideUserSuggestionsAndAvailable = matchPath(location.pathname, {
    path: [Routes.MESSAGES, Routes.PEOPLE, Routes.EXPLORE, Routes.USER_PROFILE, Routes.SCHEDULES],
  });


  return (
    <>
      <div>
        <Toaster />
      </div>
     
      <Header toggleSideBar={() => setIsSidebarOpen(!isSideBarOpen)} />

      <Root>
        <SideBar isOpen={isSideBarOpen} sideBarRef={sideBarRef} />

        <Switch>
          {auth.user.isAdmin && <Route exact path={Routes.ADMINISTRATION} component={Administration} />}

          <Route exact path={Routes.HOME} component={Home} />

          <Route exact path={Routes.SPEEDNETWORKING} component={SpeedNetworking} />

          <Route exact path={Routes.EXPLORE} component={Explore} />

          <Route exact path={Routes.PEOPLE} component={People} />

          <Route exact path={Routes.NOTIFICATIONS} component={Notifications} />

          <Route exact path={Routes.MESSAGES} component={Messages} />

          <Route exact path={Routes.MESSAGES_CALL} component={Messages} />

          <Route exact path={Routes.SCHEDULES} component={Schedule} />

          <Route exact path={Routes.SCHEDULE} component={ScheduleDetails} />

          <Route exact path={Routes.USER_PROFILE} component={Profile} />

          <Route exact path={Routes.POST} component={Post} />

          <Route exact path={Routes.ROOM} component={Room} />

          <Route component={NotFound} />
        </Switch>

        <RightSide>
          <SpacerHeader />
          {
            !hideUserSuggestionsAndAvailable &&  <><UserAvailable pathname={location.pathname} />
            <UserSuggestions pathname={location.pathname} /></>
          }
         
        
        </RightSide>
        
        {
             !hideUserSuggestionsAndAvailable &&   <Footer dark />
          }
        
      </Root>
    </>
  );
};

AppLayout.propTypes = {
  location: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
};

export default withRouter(AppLayout);
