import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GlobalStyle } from './GlobalStyles';

import { GET_AUTH_USER } from 'graphql/user';
import { GET_NEW_CONVERSATIONS_SUBSCRIPTION } from 'graphql/messages';
import { NOTIFICATION_CREATED_OR_DELETED } from 'graphql/notification';

import Message from 'components/Message';
import { Loading } from 'components/Loading';
import AuthLayout from 'pages/Auth/AuthLayout';
import NotFound from 'components/NotFound';
import AppLayout from './AppLayout';
import ScrollToTop from './ScrollToTop';

import { useStore } from 'store';
/**
 * Root component of the app
 */
const App = () => {
  const [{ message }] = useStore();

  const { loading, subscribeToMore, data, error, refetch } = useQuery(GET_AUTH_USER);
  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: NOTIFICATION_CREATED_OR_DELETED,
      updateQuery: (prev, { subscriptionData }) => {
        console.log('subscriptionData.data - NOTIFICATION_CREATED_OR_DELETED ', subscriptionData.data)
        if (!subscriptionData.data || !prev || !prev.getAuthUser) return prev;
        const oldNotifications = prev.getAuthUser.newNotifications;
        const { notification } = subscriptionData.data.notificationCreatedOrDeleted;

        // Don't show message notification in Header if user already is on messages page
        if (window.location.href.split('/')[3] === 'notifications') {
          //return prev;
        }

        // If authUser already has unseen message from that user,
        // remove old message, so we can show the new one
        const filteredOldNotifications = oldNotifications.filter((u) => u.id !== notification.id)

        // Merge conversations
        const mergeConversations = [notification, ...filteredOldNotifications];

        // Attach new conversation to authUser
        let authUser = { ...prev.getAuthUser };
        authUser.newNotifications = [...mergeConversations]

        return { getAuthUser: authUser };
      },
    });

    return () => {
      try {
        unsubscribe();
      } catch (error) {
        
      }
    };
  }, [subscribeToMore]);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: GET_NEW_CONVERSATIONS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        console.log('subscriptionData.data - GET_NEW_CONVERSATIONS_SUBSCRIPTION ', subscriptionData.data)
        if (!subscriptionData.data) return prev;
        const oldConversations = prev.getAuthUser.newConversations;
        const { newConversation } = subscriptionData.data;

        // Don't show message notification in Header if user already is on messages page
        if (window.location.href.split('/')[3] === 'messages') {
          //return prev;
        }

        // If authUser already has unseen message from that user,
        // remove old message, so we can show the new one
        const filteredOldConversations = oldConversations.filter((u) => u.id !== newConversation.id)

        // Merge conversations
        const mergeConversations = [newConversation, ...filteredOldConversations];

        // Attach new conversation to authUser
        let authUser = { ...prev.getAuthUser };
        authUser.newConversations = [...mergeConversations]

        return { getAuthUser: authUser };
      },
    });

    return () => {
      try {
        unsubscribe();
      } catch (error) {
        
      }
    };
  }, [subscribeToMore]);

  if (loading) return <Loading top="xl" />;
  if (error) {
    const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    const devErrorMessage =
      'Sorry, something went wrong. Please open the browser console to view the detailed error message.';
    const prodErrorMessage = "Sorry, something went wrong. We're working on getting this fixed as soon as we can.";
    return <NotFound message={isDevelopment ? devErrorMessage : prodErrorMessage} showHomePageLink={false} />;
  }

  return (
    <Router>
      <GlobalStyle />

      <ScrollToTop>
        <Switch>
          {data.getAuthUser ? (
            <Route exact render={() => <AppLayout authUser={data.getAuthUser} />} />
          ) : (
            <Route exact render={() => <AuthLayout refetch={refetch} />} />
          )}
        </Switch>
      </ScrollToTop>

      {message?.content?.text && (
        <Message type={message.content.type} autoClose={message.content.autoClose}>
          {message.content.text}
        </Message>
      )}
    </Router>
  );
};

export default App;
