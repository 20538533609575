import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, generatePath } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { IS_USER_ONLINE_SUBSCRIPTION } from 'graphql/user';
import { countryCodeToFlag } from 'utils/countryList';

import { H1, H3 } from 'components/Text';
import { Spacing } from 'components/Layout';
import Follow from 'components/Follow';
import ProfileImageUpload from './ProfileImageUpload';
import ProfileCoverUpload from './ProfileCoverUpload';
import ProfileDocsUpload from './ProfileDocsUpload';
import EditProfile from './EditProfile';
import Modal from 'components/Modal';
import { PencilIcon } from 'components/icons';
import AddSchedule from '../Schedule/AddSchedule';

import { useStore } from 'store';

import * as Routes from 'routes';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -140px;
`;

const Country = styled.img`
  position: absolute;
  top: 340px;
  right: calc(50% - 100px);
  border-radius:50%;
  object-fit: cover;

`;

const FullName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${(p) => p.theme.spacing.sm};
  position: relative;

  ${H1} {
    font-size: ${(p) => p.theme.font.size.lg};
  }

  @media (min-width: ${(p) => p.theme.screen.md}) {
    ${H1} {
      font-size: ${(p) => p.theme.font.size.xl};
    }
  }
`;

const FollowAndMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${(p) => p.theme.spacing.sm};
`;

const Message = styled(Link)`
  text-decoration: none;
  font-size: ${(p) => p.theme.font.size.xs};
`;

const Book = styled.div`
  text-decoration: none;
  font-size: ${(p) => p.theme.font.size.xs};
  cursor: pointer;
`;

const Online = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${(p) => p.theme.colors.success};
  margin-left: ${(p) => p.theme.spacing.sm};
  border-radius: 50%;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: ${(p) => p.theme.font.size.xs};
  margin-top: ${(p) => p.theme.spacing.sm};
`;

const List = styled.div`
  padding: 0 ${(p) => p.theme.spacing.xs};
  color: ${(p) => p.theme.colors.grey[800]};
  white-space: nowrap;

  @media (min-width: ${(p) => p.theme.screen.md}) {
    padding: 0 ${(p) => p.theme.spacing.lg};
  }
`;

const EditIcon = styled.div`
  cursor: pointer;
  background-color: white;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -50px;
`;

const CompleteProfile = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bf2f30;
  color: white;
  cursor: pointer;
`;

const A = styled.a`
  text-decoration: none;
  transition: color 0.1s;
  display: inline-block;
  color: ${(p) => (p.color ? p.theme.colors[p.color] : p.theme.colors.text.secondary)};
  font-weight: ${(p) => (p.weight ? p.theme.font.weight[p.weight] : p.theme.font.weight.normal)};
  font-size: ${(p) => (p.size ? p.theme.font.size[p.size] : p.theme.font.size.xs)};

  &:hover {
    color: ${(p) => p.theme.colors.text.primary};
  }
`;

const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;

/**
 * Renders user information in profile page
 */
const ProfileInfo = ({ user }) => {
  const { t } = useTranslation();
  const [{ auth }] = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);

  const { data, loading } = useSubscription(IS_USER_ONLINE_SUBSCRIPTION, {
    variables: { authUserId: auth.user.id, userId: user.id },
  });

  let isUserOnline = user.isOnline;
  if (!loading && data) {
    isUserOnline = data.isUserOnline.isOnline;
  }

  return (
    <Root>
      <ProfileCoverUpload userId={user.id} coverImage={user.coverImage} coverImagePublicId={user.coverImagePublicId} />

      <ProfileImage>
        <ProfileImageUpload
          userId={user.id}
          image={user.image}
          imagePublicId={user.imagePublicId}
          username={user.username}
        />
      
          {user.country && (
          <Country src={countryCodeToFlag(user.country)} alt={user.country} width={'50px'} height={'50px'} title={ user.country }/>
          )}
    
        <Spacing top="xs" />
        <FullName>
          <H1>{user.fullName}</H1>

          {isUserOnline && auth.user.id !== user.id && <Online />}

          <Spacing left="xs" />
          {auth.user.id === user.id && (
            <EditIcon onClick={() => setIsModalOpen(true)}>
              <PencilIcon />
            </EditIcon>
          )}
        </FullName>
        <Spacing top="xs" />
        {user.position && user.company && (
          <H3>
            {user.position} @{user.company}
          </H3>
        )}

        <Spacing top="xs" />
        {!!user.website && (
          <A href={withHttp(user.website)} target="_blank">
            🟢 {t('website')}
          </A>
        )}

        {auth.user.id !== user.id && (
          <FollowAndMessage>
            <Follow user={user} />

            <Spacing left="sm" />
            <Message to={generatePath(Routes.MESSAGES, { userId: user.id })}>💬 {t('messages')}</Message>
            <Spacing left="sm" />
            <Book onClick={() => setIsScheduleModalOpen(true)}>📅 {t('bookmeeting')}</Book>
          </FollowAndMessage>
        )}
      </ProfileImage>

      <Info>
        <List>
          <b>{user.posts.length} </b> posts
        </List>
        <List>
          <b>{user.followers.length} </b> followers
        </List>
        <List>
          <b>{user.following.length} </b> following
        </List>
      </Info>
      <Spacing bottom={'md'} />
      <ProfileDocsUpload userId={user.id} username={user.username} />

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <EditProfile closeModal={() => setIsModalOpen(false)} />
      </Modal>
      {auth.user.id === user.id && (!auth.user.company || !auth.user.position || !auth.user.country) && (
        <CompleteProfile onClick={() => setIsModalOpen(true)}>
          {' '}
          <PencilIcon color="white" /> Complete Profile
        </CompleteProfile>
      )}

      <Modal open={isScheduleModalOpen} onClose={() => setIsScheduleModalOpen(false)}>
        <AddSchedule onSubmit={() => setIsScheduleModalOpen(false)} user={user} />
      </Modal>
    </Root>
  );
};

ProfileInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ProfileInfo;
