import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';

import AudioVideoChat from 'components/AudioVideoChat'
import Search from 'components/Search';
import { Container } from 'components/Layout';
import Head from 'components/Head';
import MessagesChat from 'pages/Messages/MessagesChat';
import LottieRoom from 'components/LottieRoom'
import * as Routes from 'routes';


import { CREATE_MESSAGE } from 'graphql/messages';
import { GET_USER } from 'graphql/user'
import { useStore } from 'store';

const Root = styled(Container)`
  margin-top: ${(p) => p.theme.spacing.lg};

  @media (min-width: ${(p) => p.theme.screen.lg}) {
    margin-left: ${(p) => p.theme.spacing.lg};
    padding: 0;
  }
`;

const ChatContainer = styled.div`
  position: fixed;
  min-width: 348px;
  height:100%;
  max-height:calc(100% - 50px);
  right: 10px;
  bottom: 0;
  background-color: rgba(255,255,255,.2);
  backdrop-filter: blur(10px);
  box-shadow: ${(p) => p.theme.shadows.inset};
  overflow: hidden;
  
  @media (max-width: ${(p) => p.theme.screen.sm}) {
   display:none;
  }
`;


const InviteContainer = styled.div`
  display: flex;
  opacity: ${(p) => (p.hide ? 0.5 : 1)};
  border: 4px solid white;
`;


/**
 * Room page
 */
const Room = ({ match }) => {
  const history = useHistory();
  const [{ auth }] = useStore();
  const { roomId, userId } = match.params;
  const { t } = useTranslation();
  const [receiver, setReceiver] = useState();
  const [fakeMatch, setFakeMatch] = useState();
  const [createMessage, { data, error }] = useMutation(CREATE_MESSAGE);

  const { data:fetchedReceiver } = useQuery(GET_USER, {
    variables: { id: userId }
  })

  const sendMessage = () => {
    const messageText = `${t('invitemessage')}  ${
      window.location.origin + generatePath(Routes.ROOM, { roomId: roomId, userId: auth.user.id })
    }`;

    if (receiver && receiver.id === auth.user.id) return;

    createMessage({
      variables: {
        input: {
          sender: auth.user.id,
          receiver: receiver ? receiver.id : null,
          message: messageText,
        },
      },
    });
    if (data && !error) {
      toast('👏 Video Call request sent');
    }
  };




  useEffect(() => {
    if (userId === auth.user.id) {
      history.goBack();
    }
  }, [auth, userId, history]);
  
  useEffect(() => {
    if (userId) {
      let matchAsProps = {};
      matchAsProps.params = {};
      matchAsProps.params.userId = userId;
      matchAsProps.params.roomId = roomId;

      setFakeMatch(matchAsProps);
    }
  }, [roomId, userId]);

  useEffect(() => {
    if (userId && fetchedReceiver) {
      setReceiver(fetchedReceiver)
    }
  }, [userId, fetchedReceiver])
  
  useEffect(() => {
    if (receiver && receiver.id && roomId) {
      sendMessage();
      history.push(`/room/${roomId}/${receiver.id}`);
    }
    // eslint-disable-next-line
  },[receiver, roomId, history])

  


  function handleReceiver(receiver) {
    setReceiver(receiver);
  }

  return (
    <Root maxWidth="lg">
      <Head title="Private Room" />

      {!data && !receiver && (
        <InviteContainer>
         
          <Search
            onClick={(e) => {
              handleReceiver(e)
            }}
            value={receiver?.fullName}
            placeholder={t('invitePeople')}
            backgroundColor={'white'}
          />
       
        </InviteContainer>
      )}

   

      {
        receiver &&     <AudioVideoChat roomId={roomId} width={'100%'} />
      }


      {
        !receiver && <LottieRoom />
      }
  

      {userId && fakeMatch && fakeMatch.params && auth && auth.user && (
        <>
        <ChatContainer>
          <MessagesChat authUser={auth.user} match={fakeMatch} hideCallWindow={true} />
          </ChatContainer>
          </>
      )}

      
    </Root>
  );
};

export default withRouter(Room);
