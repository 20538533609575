import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { formatDistance } from 'date-fns'
import Linkify from 'react-linkify'
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Button, Textarea } from 'components/Form';
import { SendIcon } from 'components/icons';
import Avatar from 'components/Avatar';
import { Spacing } from 'components/Layout';

import dateLocale from 'utils/date'

import {checkText} from 'utils/emojiMap'

import { CREATE_MESSAGE } from 'graphql/messages';
import { GET_CONVERSATIONS } from 'graphql/user';

import * as Routes from 'routes';

const Root = styled.div`
  padding: 0 ${(p) => p.theme.spacing.sm};
  overflow-y: auto;
  height: 100vh;
  margin-top: -120px;
  padding-top: 120px;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.colors.grey[500]};
    border-radius: ${(p) => p.theme.radius.lg};
    visibility: hidden;

    &:hover {
      background-color: ${(p) => p.theme.colors.grey[600]};
    }
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }
  a{
    text-overflow: ellipsis;

/* Required for text-overflow to do anything */
white-space: nowrap;
overflow: hidden;
width:180px
  }
`;

const Conversation = styled.div`
  flex: 1;
`;

const MessageDate = styled.div`
  display:block;
  width: 100;
  font-size: ${(p) => p.theme.font.size.tiny};
  color: ${(p) => p.userMessage ? "rgba(255,255,255,.4)" :  p.theme.colors.text.secondary};
  text-align: ${(p) => (p.userMessage ? "right" : "left")};
`;

const MessageWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: ${(p) => p.userMessage && 'flex-end'};
  margin: ${(p) => p.theme.spacing.md} 0;

  &:hover ${MessageDate} {
    display: block;
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 300px;
  overflow-wrap: anywhere;
  line-height: 21px;
  font-size: ${(p) => p.theme.font.size.xs};
  padding: ${(p) => p.theme.spacing.xs} ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radius.lg};
  color: ${(p) => p.userMessage && p.theme.colors.white};
  background-color: ${(p) => (p.userMessage ? p.theme.colors.primary.light : p.theme.colors.grey[200])};
  a{
    color: ${(p) => p.userMessage && p.theme.colors.white};
  }
`;

const Form = styled.form`
  background-color: ${(p) => p.theme.colors.white};
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: ${(p) => p.theme.spacing.xxs};
`;

const StyledTextarea = styled(Textarea)`
  height: 38px;
  border-radius: ${(p) => p.theme.radius.lg};
  background-color: ${(p) => p.theme.colors.grey[200]};
`;

const SendButton = styled(Button)`
  margin-left: ${(p) => p.theme.spacing.sm};
  align-self: center;
`;

const AcceptCall = styled(Link)`
text-decoration: none;
  height: 27px;
  cursor: pointer;
  outline: none;
  font-size: ${(p) => p.theme.font.size.xxs};
  font-weight: ${(p) => p.theme.font.weight.bold};
  transition: background-color 0.2s, border-color 0.1s;
  border-radius: ${(p) => p.theme.radius.sm};
  color: ${(p) => !p.isFollowing && p.theme.colors.white};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  border: ${(p) => (p.isFollowing ? `1px solid ${p.theme.colors.border.main}` : '0')};
  background-color: #024676;

  &:hover {
    border-color: ${(p) => p.theme.colors.border.dark};
  }
`;

/**
 * Component that renders messages conversations UI
 */
const MessagesChatConversation = ({ messages, authUser, chatUser, data, match }) => {
  const { t } = useTranslation()
  
  const bottomRef = useRef(null);

  const [messageText, setMessageText] = useState('');

  const [createMessage] = useMutation(CREATE_MESSAGE);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  }, [bottomRef, data]);

  const sendMessage = (e) => {
    e.preventDefault();

    if (!messageText) return;

    if(chatUser && (chatUser.id === authUser.id)) return 

    setMessageText('');
    createMessage({
      variables: {
        input: {
          sender: authUser.id,
          receiver: chatUser ? chatUser.id : null,
          message: messageText,
        },
      },
      refetchQueries: ({ data }) => {
        if (data && data.createMessage && data.createMessage.isFirstMessage) {
          return [
            {
              query: GET_CONVERSATIONS,
              variables: { authUserId: authUser.id },
            },
          ];
        }
      },
    });
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      sendMessage(e);
    }
  };

  // Detect Call Button
  // & emojis
  const formatMessage = (message) => {
    if(!message || !message.message) return

    if (message.message.indexOf('#call#') > -1) {
      const roomId = message.message.split('#call#')[1]
      return (
        <>
          {message.sender.fullName} {' '} { t('iscallingyou')}
          
          <AcceptCall to={`/messages/${chatUser.id}/${roomId}`}>📹 {t('acceptcall')}</AcceptCall>
          </>
      )
    }
    return checkText(message.message)
  }

  return (
    <Root>
      <Conversation>
        {messages.map((message) => {
          const isAuthUserReceiver = message && message.sender && (authUser.id === message.sender.id);

          return (
            message && message.sender &&  <div key={message.id}>
            <MessageWrapper userMessage={isAuthUserReceiver} >
              {!isAuthUserReceiver && (
                <Spacing right="xs">
                  <Avatar image={message.sender.image} />
                </Spacing>
              )}

              <Message userMessage={isAuthUserReceiver}>
              <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => {
     
                      if (decoratedHref.indexOf(window.location.origin) > -1) {
                        return  <Link  to={new URL(decoratedHref).pathname} key={key}>
                                  {decoratedText}
                                </Link>
                      }

                      return (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}}
                  >
                    {formatMessage(message)}
                </Linkify>
   
                <MessageDate userMessage={isAuthUserReceiver} data-tip={new Date(parseInt(message.createdAt))} >{formatDistance(parseInt(message.createdAt), new Date(), { addSuffix: true, locale: dateLocale().formatDistance ? dateLocale() : null })}</MessageDate>
              
              </Message>

              
            </MessageWrapper>
            </div>
            
          );
        })}
        <div ref={bottomRef} />
      </Conversation>

      {match.params.userId !== Routes.NEW_ID_VALUE && chatUser && (
        <Form onSubmit={sendMessage}>
          <StyledTextarea
            placeholder="Type a message"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={onEnterPress}
          />

          <SendButton type="submit" ghost>
            <SendIcon width="28" />
          </SendButton>
        </Form>
      )}
    </Root>
  );
};

MessagesChatConversation.propTypes = {
  messages: PropTypes.array.isRequired,
  authUser: PropTypes.object.isRequired,
  chatUser: PropTypes.object,
  data: PropTypes.any,
  match: PropTypes.object.isRequired,
};

export default MessagesChatConversation;
