import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDistance } from 'date-fns';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Avatar from 'components/Avatar';

import { GET_SCHEDULES } from 'graphql/schedule';
import { useStore } from 'store';
import { useQuery } from '@apollo/client';
import { VERY_LONG_POLL_INTERVAL } from 'constants/PollInterval';
import { isBeforeOrEqualDate } from 'utils/isBeforeOrEqual';

const NextSchedule = styled(Link)`
   background-color: ${(p) => p.theme.colors.white};
  display: flex;
  text-decoration: none;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
  color: ${(p) => p.theme.colors.text.primary};
  margin-bottom: 20px;
  transition: all 1s ;
  &:hover {
    transform: scale(1.05);
  }
`;

const FullName = styled.div`
  padding-left: 10px;
`;
const When = styled.div`
  padding-left: 10px;
  font-weight: 900;
`;

/**
 * Schedule page
 */

const Schedule = () => {
  const { t } = useTranslation();
  const [{ auth }] = useStore();
  const [nextMeeting, setNextMeeting] = useState();

  const { data } = useQuery(GET_SCHEDULES, {
    variables: {
      authUserId: auth.user.id,
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: VERY_LONG_POLL_INTERVAL,
  });

  useEffect(() => {
    if (data?.getSchedules?.schedules) {
      try {
        const filterCalData = data.getSchedules.schedules
          .filter((f) => { return (!!f.confirmed && parseInt(f.schedule) >= Date.now())})
          .sort((a, b) => (a.schedule - b.schedule))[0];
        setNextMeeting(filterCalData);
      } catch (error) {
        console.error(error);
      }
      }
  }, [data]);
  
  return (
  <>
        {auth?.user?.id && nextMeeting && nextMeeting.receiver && nextMeeting.receiver.id && nextMeeting.schedule && !isBeforeOrEqualDate(nextMeeting.schedule) && (
          <NextSchedule to={`/schedule/${nextMeeting.id}`}>
            <Avatar
              size={50}
              image={
                nextMeeting?.receiver.id.toString() === auth.user.id.toString()
                  ? nextMeeting?.sender.image
                  : nextMeeting?.receiver.image
              }
            />

            <FullName>
              {' '}
              {t('meetingwith')}{' '}
              {nextMeeting?.receiver.id.toString() === auth.user.id.toString()
                ? nextMeeting?.sender.fullName
                : nextMeeting?.receiver.fullName}
            </FullName>
            <When> {formatDistance(nextMeeting.schedule, new Date(), { addSuffix: true })}</When>
          </NextSchedule>
        )}

   </>
  );
};

export default Schedule;
