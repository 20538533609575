import { gql } from '@apollo/client';


/**
 * Creates a doc
 */
export const UPLOAD_DOC = gql`
  mutation($input: CreateUploadDocInput!) {
    createUploadDoc(input: $input) {
      id
      file
      name
      shareable
    }
  }
`;


/**
 * Gets all available posts
 */
export const GET_DOCS = gql`
  query($authorId: ID!, $skip: Int, $limit: Int) {
    getUploadDocs(authorId: $authorId, skip: $skip, limit: $limit) {
      count
      uploads {
        id
        file
        name
        shareable
      }
    }
  }
`;

/**
 * Deletes a post
 */
export const DELETE_DOC = gql`
  mutation($input: DeleteUploadDocInput!) {
    deleteUploadDoc(input: $input) {
      id
    }
  }
`;
