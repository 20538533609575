import React from 'react';
import styled from 'styled-components';
import addToCal from 'utils/addToCalendar';

export const A = styled.a`
  display: block;
  width: 100%;
  height: 27px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-size: ${(p) => p.theme.font.size.xxs};
  font-weight: ${(p) => p.theme.font.weight.bold};
  transition: background-color 0.2s, border-color 0.1s;
  border-radius: ${(p) => p.theme.radius.md};
  color: ${(p) => (p.secondary ? p.theme.colors.white : p.theme.colors.black)};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  border: 1px solid ${(p) => p.theme.colors.border.white};
  background-color: ${(p) => (p.secondary ? 'black' : 'transparent')};
  margin-top: 10px;
  margin-right: 10px;

  &:active {
    background-color: black;
  }

  
  &:hover {
    transform: scale(1.01);
  }
`;

const Separation = styled.div`
width: 100%;
height: 5px;
border-top:1px solid #d5d5d5;
`

export default function AddToCalendar({ title, startTime }) {
  return (
      <>
          <Separation />
      <A
        rel="noreferrer"
        target={'_blank'}
        href={addToCal(
          {
            startTime,
            title,
          },
          'google'
        )}
      >
        ➕ Google Calendar
      </A>

      <A
        secondary
        href={addToCal({
          startTime,
          title
        })}
      >
        📥 Outlook / iCloud Calendar
      </A>
    </>
  );
}
