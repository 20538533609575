import React from 'react';
import theme from 'theme';

/**
 * Dots icon
 *
 * @param {string} width
 * @param {string} color
 */
export const CallIcon = ({ width, color }) => {
  const DEFAULT_WIDTH = '24';
  const DEFAULT_COLOR = theme.colors.text.primary;

  return (
    <svg
      width={width || DEFAULT_WIDTH}
      fill={theme.colors[color] || DEFAULT_COLOR}
      viewBox="0 0 340.165 340.165" 
      xmlns="http://www.w3.org/2000/svg"
    >
<path d="M340.165,90.246l-89.21,29.235V66.333H34.598C15.521,66.333,0,81.853,0,100.93v138.305
		c0,19.077,15.521,34.598,34.598,34.598h216.357v-53.149l89.21,29.235L340.165,90.246L340.165,90.246z M235.955,258.833H34.598
		c-10.806,0-19.598-8.792-19.598-19.598V100.93c0-10.806,8.792-19.598,19.598-19.598h201.357L235.955,258.833L235.955,258.833z
		 M325.165,229.219L253.4,205.7v-71.235l71.765-23.519L325.165,229.219L325.165,229.219z"/>
    </svg>
  );
};
