import React from 'react';
import PropTypes from 'prop-types';
import {  withRouter } from 'react-router-dom';
import styled from 'styled-components';

import Navigation from './Navigation';
import { Spacing } from 'components/Layout';

import { SIDEBAR_DESKTOP_WIDTH, SIDEBAR_MOBILE_WIDTH, HEADER_HEIGHT } from 'constants/Layout';

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding-top: ${HEADER_HEIGHT + 40}px;
  height: 100%;
  width: ${SIDEBAR_MOBILE_WIDTH}px;
  transition: margin-left 0.2s ease-in-out;
  font-size: ${(p) => p.theme.font.size.sm};
  z-index: ${(p) => p.theme.zIndex.sm};
  background-color: ${(p) => p.theme.colors.white};
  border-right: 1px solid ${(p) => p.theme.colors.border.main};

  @media (min-width: ${(p) => p.theme.screen.md}) {
    padding-top: 0;
    position: sticky;
    top: 100px;
    margin-left: ${(p) => (p.isOpen ? 0 : `-${SIDEBAR_DESKTOP_WIDTH}px`)};
    flex-basis: ${SIDEBAR_DESKTOP_WIDTH}px;
    flex-grow: 0;
    flex-shrink: 0;
    border: 0;
    background-color: transparent;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    margin-left: ${(p) => (p.isOpen ? 0 : `-${SIDEBAR_MOBILE_WIDTH}px`)};
  }
`;

const Signature = styled.div`
  margin-top: 10px;
  font-size: 11px;
`




/**
 * Displays left side bar
 */
const SideBar = ({ location, isOpen, sideBarRef }) => {
  //const [{ auth }] = useStore();

  //const isAuthUsersProfilePage = auth.user.username === location.pathname.substring(1);

  return (
    <Root isOpen={isOpen} ref={sideBarRef}>
      <Navigation />
      <Spacing top={'xs'} />
     
      <img alt='#' src='https://btt.ma/wp-content/uploads/2021/09/logos.png' style={{
        height: '80px',
        width: 'auto',
        maxWidth: '100%'
      }} />
       <Spacing top={'xs'} />
      <Signature>© Regional Council of Tourism of Tangier Tetouan Al Hoceima</Signature>
      <Signature>Platform created by <a href='https://ctd.ma' target='_blank' rel="noreferrer" >Casbah Tourism Development</a></Signature>
     
      
     
    </Root>
  );
};

SideBar.propTypes = {
  location: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default withRouter(SideBar);
