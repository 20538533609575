import React from 'react';
import { NavLink, generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import * as Routes from 'routes';

import { ExploreIcon, NotificationIcon, HomeIcon, PeopleIcon, EnvelopeIcon, ScheduleIcon } from 'components/icons';
import { useStore } from 'store';

const Link = styled(NavLink)`
  text-decoration: none;
  transition: color 0.1s;
  color: ${(p) => p.theme.colors.text.primary};
  display: block;
  padding-left: ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radius.md};;

  &:hover{
    background-color: ${(p) => p.theme.colors.grey[50]};
  };
  &.selected {
    color: ${(p) => p.theme.colors.white};
    background: linear-gradient(90deg, rgba(6,111,197,1) 0%, rgba(0,163,255,1) 100%);

    svg path {
      fill: ${(p) => p.theme.colors.white};
    }

  
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  line-height: 40px;
  font-size: ${(p) => p.theme.font.size.xs};
  
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  
`;

const Name = styled.div`
  margin-left: ${(p) => p.theme.spacing.sm};
`;

/**
 * Navigation component used in SideBar
 */
const Navigation = () => {
  const [{ auth }] = useStore();
  const { t } = useTranslation()
  return (
    <List>

      { auth.user.isAdmin && 
          <Link exact activeClassName="selected" to={Routes.ADMINISTRATION}>
          <ListItem>
          🎫
            <Name>{ t('Ticket Office')}</Name>
          </ListItem>
        </Link>
      }
      
      <Link exact activeClassName="selected" to={Routes.HOME}>
        <ListItem>
          <HomeIcon />
          <Name>{ t('community')}</Name>
        </ListItem>
      </Link>
      <Link exact activeClassName="selected" to={Routes.PEOPLE}>
        <ListItem>
          <PeopleIcon />
          <Name>{ t('network')}</Name>
        </ListItem>
      </Link>
      <Link exact activeClassName="selected" to={Routes.SCHEDULES}>
        <ListItem>
          <ScheduleIcon width={20}/>
          <Name>{ t('schedule')}</Name>
        </ListItem>
      </Link>
      <Link exact activeClassName="selected" to={generatePath(Routes.MESSAGES, { userId: Routes.NEW_ID_VALUE })}>
        <ListItem>
          <EnvelopeIcon width={18} />
          <Name>{ t('messages')}</Name>
        </ListItem>
      </Link>

    

      <Link exact activeClassName="selected" to={Routes.EXPLORE}>
        <ListItem>
          <ExploreIcon width={20} />
          <Name>{ t('explore')}</Name>
        </ListItem>
      </Link>

      <Link exact activeClassName="selected" to={Routes.NOTIFICATIONS}>
        <ListItem>
          <NotificationIcon width={18} />
          <Name>{ t('notifications')}</Name>
        </ListItem>
      </Link>

    
    </List>
  );
};

export default Navigation;
