import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components'

const HideOnMobile = styled.div`
      
@media (max-width: ${(p) => p.theme.screen.md}) {
   display:none;
}
`

const LottieSocial = () => (
    <HideOnMobile>
  <Player
        autoplay
        bounce
    loop
    src="https://assets8.lottiefiles.com/private_files/lf30_ohuluwv5.json"
    style={{ height: '100px', width: '100px' }}
  >
        </Player>
        </HideOnMobile>
)

export default LottieSocial