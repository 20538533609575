import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { generatePath, withRouter, Link, useHistory } from 'react-router-dom';
import { useSubscription } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Search from 'components/Search';
import Avatar from 'components/Avatar';
import DraggableModalCall from 'components/DraggableModalCall';

import { CallIcon } from 'components/icons';

import { IS_USER_ONLINE_SUBSCRIPTION, GET_CONVERSATIONS } from 'graphql/user';
import { CREATE_MESSAGE } from 'graphql/messages';

import uid from 'utils/uid';

import * as Routes from 'routes';

import { useStore } from 'store';

const Root = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid ${(p) => p.theme.colors.border.main};
  z-index: 1;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${(p) => p.theme.spacing.sm};
  color: ${(p) => p.theme.colors.text.secondary};
  font-size: ${(p) => p.theme.font.size.xs};
`;

const To = styled.div`
  margin-top: 1px;
`;

const User = styled(Link)`
  margin: 0 ${(p) => p.theme.spacing.xxs};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xxs};
  border-radius: ${(p) => p.theme.radius.md};
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`;

const Info = styled.div`
  padding-left: ${(p) => p.theme.spacing.xs};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const FullName = styled.div`
  font-size: ${(p) => p.theme.font.size.sm};
  color: ${(p) => p.theme.colors.text.primary};
  font-weight: ${(p) => p.theme.font.weight.bold};
`;

const Online = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: ${(p) => p.theme.colors.success};
  margin-left: ${(p) => p.theme.spacing.xs};
  border-radius: 50%;
`;

const CallIconContainer = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

/**
 * Heading component for messages chat
 */
const MessagesChatHeading = ({ location, match, chatUser, hideCallWindow }) => {
  const { t } = useTranslation()
  const history = useHistory();
  const [{ auth }] = useStore();
  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [roomId, setRoomId] = useState();
  const [showCallModal, setShowCallModal] = useState(false);
  
  let isOnline = false;

  useEffect(() => {
    if (match.params.roomId) {
      setRoomId(match.params.roomId);
      setShowCallModal(true);
    } else {
      setShowCallModal(false);
    }
  }, [match]);

  const sendMessage = (e) => {
    if (match.params.roomId) return;

    const uniq = uid();

    try {
      
      if  (chatUser && chatUser.id === auth.user.id) return 

      createMessage({
        variables: {
          input: {
            sender: auth.user.id,
            receiver:  chatUser ? chatUser.id : null,
            message: `#call#${uniq}`,
          },
        },
        refetchQueries: ({ data }) => {
          if (data && data.createMessage && data.createMessage.isFirstMessage) {
            return [
              {
                query: GET_CONVERSATIONS,
                variables: { authUserId: auth.user.id },
              },
            ];
          }
        },
      });
      history.push(window.location.pathname + '/' + uniq);
    } catch (error) {
      console.error(error);
    }
  };

  const { data, loading } = useSubscription(IS_USER_ONLINE_SUBSCRIPTION, {
    variables: {
      authUserId: auth.user.id,
      userId: chatUser ? chatUser.id : null,
    },
    skip: !chatUser,
  });

  // Update user's isOnline field in real time
  if (!loading && data && chatUser) {
    isOnline = data.isUserOnline.isOnline;
  }

  if (match.params.userId === Routes.NEW_ID_VALUE || !chatUser) {
    return (
      <Root>
        <InputContainer>
          <To>{ t('to')}:</To>
          <Search
            location={location}
            backgroundColor="white"
            hideIcon
            forMessage
            placeholder={t('typepersonname')}
            autoFocus
          />
        </InputContainer>
      </Root>
    );
  }

  if (chatUser) {
    return (
      <Root>
        <User
          to={generatePath(Routes.USER_PROFILE, {
            username: chatUser.username,
          })}
        >
          <Avatar image={chatUser.image} size={40} />

          <Info>
            <FullName>{chatUser.fullName}</FullName>

            {isOnline && <Online />}
          </Info>
        </User>
        {
          !hideCallWindow && 
          <CallIconContainer
            data-tip={'Call ' + chatUser.fullName}
            onClick={() => {
              sendMessage();
            }}
          >
            <CallIcon />
          </CallIconContainer>
}

        {showCallModal && roomId && !hideCallWindow &&  (
          <DraggableModalCall
            roomId={roomId}
            onClose={() => {
              history.push('/messages/' + chatUser.id);
              setRoomId(null);
            }}
          />
        )}
      </Root>
    );
  }

  return null;
};

MessagesChatHeading.propTypes = {
  match: PropTypes.object.isRequired,
  chatUser: PropTypes.object,
};

export default withRouter(MessagesChatHeading);
