import React, { useState } from 'react';
import styled from 'styled-components';
import { useStore } from 'store';
import { useApolloClient } from '@apollo/client';
import toast, { Toaster } from 'react-hot-toast';
import Select from 'react-select';

import { GET_AUTH_USER, UPDATE_USER } from 'graphql/user';
import { InputText, Button } from 'components/Form';
import { Spacing } from 'components/Layout';
import countryList from 'utils/countryList'

const countryOptions = Object.keys(countryList)
  .map(f=>  {return{ value:  f, label: countryList[f] }})

const Root = styled.div`
  margin: 0 auto;
  margin: ${(p) => !p.usedInModal && p.theme.spacing.lg} 0;
  box-shadow: ${(p) => p.theme.shadows.sm};
  border-radius: ${(p) => p.theme.radius.sm};
  z-index: ${(p) => (p.usedInModal ? p.theme.zIndex.xl : 'inherit')};
  overflow: hidden;
  width: 100%;

  @media (min-width: ${(p) => p.theme.screen.md}) {
    width: auto;
  }
`;

const Form = styled.div`
  padding: ${(p) => p.theme.spacing.md};
  border-radius: ${(p) => p.theme.radius.sm};
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.shadows.inset};
  width: 100%;

  @media (min-width: ${(p) => p.theme.screen.sm}) {
    width: 500px;
  }
`;

/**
 * Updates user profile
 */
const EditProfile = ({ closeModal = () => {} }) => {
  const [{ auth }] = useStore();
  const client = useApolloClient();
  const [values, setValues] = useState({
    fullName: auth.user.fullName,
    company: auth.user.company,
    position: auth.user.position,
    timezone: window.Intl.DateTimeFormat().resolvedOptions().timeZone,
    country: auth.user.country,
    address: auth.user.address,
    city: auth.user.city,
    website: auth.user.website,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSelectCountryChange = ({value}) => {
    setValues({ ...values, country: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          input: {
            authUserId: auth.user.id,
            fullName: fullName,
            position: position,
            company: company,
            timezone: timezone,
            country: country,
            address: address,
            city: city,
            website: website,
          },
        },
        refetchQueries: () => [
          { query: GET_AUTH_USER }
        ],
      });
      closeModal();
      toast('👏 Updated')
    } catch (err) {
     console.error(err)
    }
  };

  const {
    fullName,
    company,
    position,
    country,
    address,
    city,
    website,
    timezone
  } = values;

  return (
    <Root usedInModal>
      <Toaster />
      <Form>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Spacing top="xs" bottom="xs" />
          <InputText
            type="text"
            name="fullName"
            values={fullName}
            onChange={handleChange}
            placeholder={fullName || 'full name'}
          />
          <Spacing top="xs" bottom="xs" />

          <InputText
            type="text"
            name="position"
            values={position}
            onChange={handleChange}
            placeholder={position || 'position'}
          />
          <Spacing top="xs" bottom="xs" />

          <InputText
            type="text"
            name="company"
            values={company}
            onChange={handleChange}
            placeholder={company || 'company'}
          />
          <Spacing top="xs" bottom="xs" />

       
          <Select
            placeholder={countryList[country]}
            value={country}
            onChange={handleSelectCountryChange}
            options={countryOptions}
        />
          <Spacing top="xs" bottom="xs" />

          <InputText
            type="text"
            name="address"
            values={address}
            onChange={handleChange}
            placeholder={address || 'Address'}
          />
          <Spacing top="xs" bottom="xs" />

          <InputText
            type="text"
            name="city"
            values={city}
            onChange={handleChange}
            placeholder={city || 'City'}
          />
          <Spacing top="xs" bottom="xs" />

          <InputText
            type="text"
            name="website"
            values={website}
            onChange={handleChange}
            placeholder={website || 'website'}
          />
          <Spacing top="xs" bottom="xs" />

          <Button size="large">Update profile</Button>
        </form>
      </Form>
    </Root>
  );
};

export default EditProfile;
