/**
 * converts a youtube url to youtube ID
 * 
 * @param {*} url 
 * @returns 
 */
export default function youtube_parser(url) {
    if(!url) { return }
    var regExp = /(?:youtube\.com(?:[^]+.+|(?:v|e(?:mbed)?)|.*[?&]v=)|youtu\.be)([^"&?\s]{11})/i;
    var match = url.match(regExp);
    return (match && match[1].length===11)? match[1] : false;
}