import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Container } from 'components/Layout';
import Skeleton from 'components/Skeleton';
import { Loading } from 'components/Loading';
import Empty from 'components/Empty';
import InfiniteScroll from 'components/InfiniteScroll';
import Head from 'components/Head';
import PeopleCard from './PeopleCard';
import countryList, { countryCodeToFlag } from 'utils/countryList';

import { GET_USERS } from 'graphql/user';

import { PEOPLE_PAGE_USERS_LIMIT } from 'constants/DataLimit';

import { useStore } from 'store';

import { useQuery } from '@apollo/client';

const Root = styled(Container)`
  margin-top: ${(p) => p.theme.spacing.lg};

  @media (min-width: ${(p) => p.theme.screen.lg}) {
    margin-left: ${(p) => p.theme.spacing.lg};
    padding: 0;
  }
`;

const PeopleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 3fr));
  grid-auto-rows: auto;
  grid-gap: 20px;
  margin-bottom: ${(p) => p.theme.spacing.lg};
`;

const SelectCountryContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding-bottom: 10px;
  width: 100%;
  overflow-x: auto;

  ::-webkit-scrollbar {
    padding-top: 10px;
    height: 5px;
    overflow: visible;
  }
`;

const SelectCountry = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
  cursor: pointer;
  margin: 0 auto;
`;

/**
 * People page
 */
const People = () => {
  const [{ auth }] = useStore();
  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const variables = {
    userId: auth.user.id,
    skip: 0,
    limit: PEOPLE_PAGE_USERS_LIMIT,
  };

  const { data, loading, fetchMore, networkStatus } = useQuery(GET_USERS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  //list countries
  useEffect(() => {
    if (Array.isArray(data?.getUsers?.users)) {
      const c = data.getUsers.users.map((d) => d.country).filter((c) => c);
      setCountries([...new Set(c)]);
    }
  }, [data]);

  //filter by countries
  useEffect(() => {
    if (country && Array.isArray(data?.getUsers?.users)) {
      const filtred = data.getUsers.users.filter((c) => c.country === country);
      setFiltered(filtred);
    }
  }, [country, data]);

  const renderContent = () => {
    if (loading && networkStatus === 1) {
      return (
        <PeopleContainer>
          <Skeleton height={280} count={PEOPLE_PAGE_USERS_LIMIT} />
        </PeopleContainer>
      );
    }

    const { users, count } = data.getUsers;
    if (!users.length > 0) return <Empty text="No people yet." />;

    return (
      <>
        <SelectCountryContainer>
          {countries &&
            countries.map((c, k) => (
              <div key={c + k} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <SelectCountry  src={countryCodeToFlag(c)} onClick={() => setCountry(c)} />
                <p style={{margin: '5px', fontSize:'13px', overflow: 'hidden', textOverflow: 'ellipsis', width: '60px', whiteSpace: 'nowrap', textAlign: 'center'}}>{countryList[c]}</p>
              </div>
            ))}
        </SelectCountryContainer>

        {Array.isArray(filtered) && filtered.length > 0 && (
          <Fragment>
            <PeopleContainer>
              {filtered.map((user) => (
                <PeopleCard key={user.id} user={user} />
              ))}
            </PeopleContainer>
          </Fragment>
        )}

        <InfiniteScroll
          data={users}
          dataKey="getUsers.users"
          count={parseInt(count)}
          variables={variables}
          fetchMore={fetchMore}
        >
          {(data) => {
            const showNextLoading = loading && networkStatus === 3 && count !== data.length;

            return (
              <Fragment>
                <PeopleContainer>
                  {data.map((user) => (
                    <PeopleCard key={user.id} user={user} />
                  ))}
                </PeopleContainer>

                {showNextLoading && <Loading top="lg" />}
              </Fragment>
            );
          }}
        </InfiniteScroll>
      </>
    );
  };

  return (
    <Root maxWidth="md">
      <Head title="Find new People" />

      {renderContent()}
    </Root>
  );
};

export default People;
