import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import VideoChatRoom from './VideoChatRoom';
import LottieEmptyPeers from 'components/LottieEmptyPeers';
import { Button } from 'components/Form';
import { Container } from 'components/Layout';
import Head from 'components/Head';
import { useStore } from 'store';
import { useMutation, useSubscription } from '@apollo/client';

import { UPDATE_USER, NEW_PAIR_SUBSCRIPTION, GET_USER } from 'graphql/user';


const Root = styled(Container)`
  margin-top: ${(p) => p.theme.spacing.lg};

  @media (min-width: ${(p) => p.theme.screen.lg}) {
    margin-left: ${(p) => p.theme.spacing.lg};
    padding: 0;
  }
`;

const NextContainer = styled.div`
  margin:20px;
  margin-left:0;
  width:100%;
  max-width:250px;
  display: flex;
  justify-content: space-between;
  button{
  margin-left:10px
  }
`
const NextBtn = styled(Button)`
  width: 100%;
  min-width: 300px;
  animation: pulse 2s infinite;
  @keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
`


const Instructions = styled.div`
  margin-top:10px;
  font-size: 12px;
  margin-left:15px;
  span{
    background-color: #5571E6;
    color: white;
    padding: 5px;

  }
`

/**
 * Room page
 */
const Room = () => {
  const { t } = useTranslation()
  const [{ auth }] = useStore()
  const [match, setMatch] = useState(null)
  const [pause, setPause] = useState(false)

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [
      { query: GET_USER, variables: { username: auth.user.username } },
    ],
  });


  const { data } = useSubscription(NEW_PAIR_SUBSCRIPTION, {
    variables: { authUserId: auth.user.id },
  });

  console.log('data?.getNewPair', data?.getNewPair)

  const handleUpdateUserAvailability = async (bool) => {
    console.log('UPDATING USER AVAILIBILITY TO ', bool)
    await updateUser({
      variables: {
        input: {
          authUserId: auth.user.id,
          isAvailable: !!bool,
        },
      },
    });
  };

  useEffect(() => {
    console.log('User is available to speed networking')
    handleUpdateUserAvailability(true);

    return function () {
      console.log('User is NOT available to speed networking')
      handleUpdateUserAvailability(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && data.getNewPair && data.getNewPair.pairs) {
      const myMatch = data.getNewPair.pairs.filter((p) => p.pair.indexOf(auth.user.id) > -1)[0];
      //if user have a match
      if (myMatch) {
        console.log('setMatch(myMatch)', myMatch)
        setMatch(myMatch);
      }

      //if user is available
      if (data.getNewPair.users.indexOf(auth.user.id)>-1) {
        setMatch(null)
      }

    }

   
  
  }, [data, auth.user.id, match]);

  return (
    <Root maxWidth="lg" style={{ marginTop: 0 }}>
     
      <Head title="Speed Networking" />

      {
        match && !pause && <Instructions  onClick={() => {
          setMatch(null);
          handleUpdateUserAvailability(true);
        }}>{ t('ifYouAreWaiting')}<span>NEXT</span></Instructions>
      }
      {match && !pause && (
        <NextContainer>
          <NextBtn
            fullWidth
            onClick={() => {
              setMatch(null);
              handleUpdateUserAvailability(true);
          }}
        >
          NEXT
          </NextBtn>

          <Button
            text
            fullWidth
            onClick={() => {
              setMatch(null);
              handleUpdateUserAvailability(false);
              setPause(true)
          }}
        >
          PAUSE
          </Button>

     
          </NextContainer>
      )}

      {!pause && match && match.room && (
        <VideoChatRoom
          roomId={match.room}
          userId={match.pair[0].toString() === auth.user.id.toString() ? match.pair[1] : match.pair[0]}
          auth={auth}
        />
      )}

      {(!match || pause) && <LottieEmptyPeers userCount={data?.getNewPair?.count || 0} pause={pause} onResume={() => {
        setMatch(null);
        handleUpdateUserAvailability(true);
        setPause(false)
      }} />}

      
     

    </Root>
  );
};

export default withRouter(Room);
