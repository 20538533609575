import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { generatePath } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { A } from 'components/Text';
import PostPopup from 'components/PostPopup';
import Modal from 'components/Modal';
import PostCard from 'components/PostCard';
import { Spacing, Container } from 'components/Layout';
import { Loading } from 'components/Loading';
import InfiniteScroll from 'components/InfiniteScroll';
import Skeleton from 'components/Skeleton';
import CreatePost from 'components/CreatePost';
import Head from 'components/Head';
import OnlineUsers from 'components/OnlineUsers'
import NextMeeting from 'pages/Schedule/NextMeeting'
import SpeedNetworkingTimer from 'components/SpeedNetworkingTimer'

import { GET_FOLLOWED_POSTS, GET_ADMINS_POSTS } from 'graphql/post';

import { useStore } from 'store';

import { HOME_PAGE_POSTS_LIMIT } from 'constants/DataLimit';
import { LONG_POLL_INTERVAL } from 'constants/PollInterval'
import * as Routes from 'routes';

const Empty = styled.div`
  padding: ${(p) => p.theme.spacing.sm};
  border: 1px solid ${(p) => p.theme.colors.border.main};
  border-radius: ${(p) => p.theme.radius.sm};
  margin-top: ${(p) => p.theme.spacing.lg};
  background-color: ${(p) => p.theme.colors.white};
`;

const StyledA = styled(A)`
  text-decoration: underline;
  font-weight: ${(p) => p.theme.font.weight.bold};
`;

/**
 * Home page of the app
 */
const Home = () => {
  const [{ auth }] = useStore();
  const [modalPostId, setModalPostId] = useState(null);
  const { t } = useTranslation()
  const variables = {
    userId: auth.user.id,
    skip: 0,
    limit: HOME_PAGE_POSTS_LIMIT,
  };

  const { data, loading, fetchMore, networkStatus } = useQuery(GET_FOLLOWED_POSTS, {
    variables,
    notifyOnNetworkStatusChange: true,
    pollInterval: LONG_POLL_INTERVAL*2,
  });

  const { data: adminData, loading: adminDataLoading } = useQuery(GET_ADMINS_POSTS, {
    variables,
    notifyOnNetworkStatusChange: true,
    pollInterval: LONG_POLL_INTERVAL,
  });

  const closeModal = () => {
    window.history.pushState('', '', '/');
    setModalPostId(null);
  };

  const openModal = (postId) => {
    window.history.pushState('', '', generatePath(Routes.POST, { id: postId }));
    setModalPostId(postId);
  };

  const renderContent = () => {
    if ((loading || adminDataLoading) && networkStatus === 1) {
      return <Skeleton height={500} bottom="lg" top="lg" count={HOME_PAGE_POSTS_LIMIT} />;
    }

    const { posts, count } = data.getFollowedPosts;
    const getAdminPosts = Array.isArray(posts) ? posts.filter(p => p.author.isAdmin) : []
    const countAdmins = getAdminPosts.length

    return (
      <>
        
        {
          (!posts.length || (parseInt(count) === parseInt(countAdmins))) && (
            <>
         
            <Empty>
              
              <StyledA to={generatePath(Routes.EXPLORE)}>{t('explorenewposts')}</StyledA> { t('or') } {' '}
              <StyledA to={generatePath(Routes.PEOPLE)}>{t('findnewpeople')}</StyledA>
              </Empty>
              
              </>
          )
        }

{ adminData?.getAdminsPosts?.posts && adminData.getAdminsPosts.posts[0] && adminData.getAdminsPosts.posts[0].id && 
            <Fragment key={adminData.getAdminsPosts.posts[0].id}>
            <Modal open={modalPostId === adminData.getAdminsPosts.posts[0].id} onClose={closeModal}>
              <PostPopup id={adminData.getAdminsPosts.posts[0].id} closeModal={closeModal} />
            </Modal>

            <Spacing bottom="lg" top="lg">
              <PostCard
                author={adminData.getAdminsPosts.posts[0].author}
                imagePublicId={adminData.getAdminsPosts.posts[0].imagePublicId}
                postId={adminData.getAdminsPosts.posts[0].id}
                comments={adminData.getAdminsPosts.posts[0].comments}
                createdAt={adminData.getAdminsPosts.posts[0].createdAt}
                title={adminData.getAdminsPosts.posts[0].title}
                image={adminData.getAdminsPosts.posts[0].image}
                likes={adminData.getAdminsPosts.posts[0].likes}
                openModal={() => openModal(adminData.getAdminsPosts.posts[0].id)}
              />
            </Spacing>
          </Fragment>
          }

      <InfiniteScroll
        data={posts}
        dataKey="getFollowedPosts.posts"
        count={parseInt(count)}
        variables={variables}
        fetchMore={fetchMore}
        >
          
        {(data) => {
          const showNextLoading = loading && networkStatus === 3 && count !== data.length;

          return (
            <Fragment>
              {data.map((post) => (
                <Fragment key={post.id}>
                  <Modal open={modalPostId === post.id} onClose={closeModal}>
                    <PostPopup id={post.id} closeModal={closeModal} />
                  </Modal>

                  <Spacing bottom="lg" top="lg">
                    <PostCard
                      author={post.author}
                      imagePublicId={post.imagePublicId}
                      postId={post.id}
                      promoted={post.promoted}
                      comments={post.comments}
                      createdAt={post.createdAt}
                      title={post.title}
                      image={post.image}
                      likes={post.likes}
                      isAdmin={auth.user.isAdmin}
                      openModal={() => openModal(post.id)}
                    />
                  </Spacing>
                </Fragment>
              ))}

              {showNextLoading && <Loading top="lg" />}
            </Fragment>
          );
        }}
        </InfiniteScroll>
        </>
    );
  };

  return (
    <Container maxWidth="sm">
      <Head />

      <Spacing top="lg" />

      <CreatePost />
      
      <Spacing top="lg" />
    
      <OnlineUsers />
      <Spacing top="lg" />
      <SpeedNetworkingTimer />
      <Spacing top="lg" />
      <NextMeeting />

      {renderContent()}
    </Container>
  );
};

export default Home;
