import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { matchPath } from 'react-router';
import { generatePath } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Loading } from 'components/Loading';
import { H3, A } from 'components/Text';
import { Spacing } from 'components/Layout';
import Avatar from 'components/Avatar';
import { RefreshIcon } from 'components/icons';

import { useStore } from 'store';

import { USER_SUGGESTIONS } from 'graphql/user';

import { USER_SUGGESTIONS_WIDTH, HEADER_HEIGHT } from 'constants/Layout';

import * as Routes from 'routes';

export const SpacerHeader = styled.div`
  margin-top: ${HEADER_HEIGHT - 10}px;
`

const Root = styled.div`
  display: none;
  position: relative;
  
  height: 390px;
  overflow-y: auto;
  width: ${USER_SUGGESTIONS_WIDTH}px;
  padding: ${(p) => p.theme.spacing.sm};
  border-radius: ${(p) => p.theme.radius.md};
  box-shadow:inset 0px 1px 4px 0px #adb1cd;
  background-color: white;

  @media (min-width: ${(p) => p.theme.screen.md}) {
    display: block;
  }
`;

const List = styled.ul`
  padding: 0;
  padding-top: ${(p) => p.theme.spacing.xs};
`;

const ListItem = styled.li`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin-bottom: ${(p) => p.theme.spacing.sm};

  &:last-child {
    margin-bottom: 0;
  }
`;

const Refrech = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    height: 27px;
    cursor: pointer;
    outline: none;
    font-size: ${(p) => p.theme.font.size.xxs};
    font-weight: ${(p) => p.theme.font.weight.bold};
    transition: background-color 0.2s, border-color 0.1s;
    border-radius: ${(p) => p.theme.radius.sm};
    color: ${(p) => !p.isFollowing && p.theme.colors.white};
    padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
`

const FullName = styled.div`
  font-weight: ${(p) => p.theme.font.weight.bold};
  color: ${(p) => (p.active ? p.theme.colors.primary.main : p.theme.colors.text.primary)};
`;

const UserName = styled.div`
  color: ${(p) => p.theme.colors.text.hint};
`;

/**
 * Displays user suggestions
 */
const UserSuggestions = ({ pathname }) => {
  const { t } = useTranslation()
  const [{ auth }] = useStore();
  const { data, loading, refetch } = useQuery(USER_SUGGESTIONS, {
    variables: { userId: auth.user.id },
    notifyOnNetworkStatusChange: true
  });

  const hideUserSuggestions = matchPath(pathname, {
    path: [Routes.MESSAGES, Routes.PEOPLE, Routes.EXPLORE, Routes.USER_PROFILE, Routes.SCHEDULES],
  });

  if (hideUserSuggestions) return null;

  if (loading) {
    return (
      <Root>
        <Loading />
      </Root>
    );
  }

  if (!data?.suggestPeople.length > 0) {
    return null;
  }

  return (
    <Root>
      <H3>{ t('suggestionsforyou')}</H3>

      <List>
        {data.suggestPeople.map((user) => {
          if (!user.username) return null;

          return (
            <ListItem key={user.username+'sugg'}>
              <A
                to={generatePath(Routes.USER_PROFILE, {
                  username: user.username,
                })}
              >
                <Avatar image={user.image} />
              </A>

              <Spacing left="xs">
                <A
                  to={generatePath(Routes.USER_PROFILE, {
                    username: user.username,
                  })}
                >
                  <FullName>{user.fullName}</FullName>
                  <UserName>@{user.username}</UserName>
                </A>
              </Spacing>
            </ListItem>
          );
        })}
      </List>
      <Refrech onClick={() => refetch()}>
        <RefreshIcon width={20}/>
      </Refrech>
    </Root>
  );
};

UserSuggestions.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default UserSuggestions;
