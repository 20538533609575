import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import cdnFormatter from 'utils/cdnFormatter'

import { UserIcon } from './icons';

const Root = styled.div`
  width: ${(p) => (p.size ? `${p.size}px` : '30px')};
  height: ${(p) => (p.size ? `${p.size}px` : '30px')};
  flex-shrink: 0;
  position: relative;
  border:  ${(p) => (p.isAdmin ? `2px solid #0869ae` : 'none')};
  animation: ${(p) => (p.isAdmin ? `pulse 2s infinite` : 'none')}; ;
  border-radius: 50%;
  @keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
  
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;

`;

const Online = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${(p) => p.theme.colors.success};
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999999;
`;

/**
 * Component for rendering user's image
 */
const Avatar = ({ size, image, isOnline, isAdmin=false }) => (
  <Root size={size} isAdmin={isAdmin}>
    {image ? <Image src={cdnFormatter(image)} /> : <UserIcon width={size ? `${size}px` : 30} />}
    {
      isOnline && <Online />
    }

  </Root>
);

Avatar.propTypes = {
  size: PropTypes.number,
  image: PropTypes.string,
  isOnline: PropTypes.bool,
  isAdmin: PropTypes.bool
};

export default Avatar;
