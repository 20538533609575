import React, { useState, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Button } from 'components/Layout';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import LottieTime from 'components/LottieTime';
import LottieJoinSpeedNetworking from 'components/LottieJoinSpeedNetworking'
import { Spacing } from './Layout';

import * as Routes from 'routes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NextSessing = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  padding: 10px;
  padding-bottom: 20px;
`;

const SpeedNetworkingBtn = styled(Button)`
  background: linear-gradient(90deg, rgba(6, 111, 197, 1) 0%, rgba(0, 163, 255, 1) 100%);
  font-size: 16px;

`;

const SpeedNetworkingDisabledBtn = styled(Button)`
  background: grey;
  font-size: 16px;
  cursor: not-allowed;
`;

const Title = styled.div`
  word-break: break-word;
  white-space: pre-line;
  font-size: 24px;
 
  span{
    background: linear-gradient(0deg, #f9dd38 6px, transparent 7px) 0 1.1em;
  }
`;

/**
 * Component for displaying when there is no data provided
 */
const SpeedNetworkingTimer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [next, setNext] = useState(1);

  function nextHalfHour() {
    try {
      const now = new Date();
      now.setHours(now.getHours() + 1);
      now.setMinutes(30);
      const diffMs = now - new Date();
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      setNext(Math.max(diffMins, 1));
    } catch (error) {}
  }

  useEffect(() => {
    nextHalfHour();
    const interval = setInterval(() => {
      nextHalfHour();
    }, 1000);

    return function () {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container>
      <NextSessing>
        {((next > 5) && (next <= 59)) && (
          <>
            <LottieTime />
            {t('nextSpeedNetworkingStarts')}{' '}
            <Spacing top="xs" />
            <Title>
             <span> {next} {t('minutes')}</span>
            </Title>
            <Spacing top="md" />
            <SpeedNetworkingDisabledBtn>{t('joinSpeedNetworking')}</SpeedNetworkingDisabledBtn>
          </>
        )}

        {(next >= 0) && (next <= 5) && (
          <>
             <Title>
              {t('youHaveLessThan')} <span>{next} {next <= 1 ? t('minute') : t('minutes')}</span>
            </Title>
            <LottieJoinSpeedNetworking />
           
            <SpeedNetworkingBtn onClick={() => history.push(generatePath(Routes.SPEEDNETWORKING))}>
              {t('joinSpeedNetworking')}
            </SpeedNetworkingBtn>
          </>
        )}
      </NextSessing>
    </Container>
  );
};

export default SpeedNetworkingTimer;
