import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { matchPath } from 'react-router';
import { generatePath, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Loading } from 'components/Loading';
import { Spacing, Button } from 'components/Layout';
import Avatar from 'components/Avatar';

import { useStore } from 'store';

import { GET_AVAILABLE_USERS } from 'graphql/user';

import { USER_SUGGESTIONS_WIDTH } from 'constants/Layout';

import * as Routes from 'routes';

const Root = styled.div`
  display: none;
  position: relative;
  width: ${USER_SUGGESTIONS_WIDTH}px;
 
  border-radius: ${(p) => p.theme.radius.md};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: ${(p) => p.theme.screen.md}) {
    display: flex;
  }
`;







const FullName = styled.div`
  font-weight: ${(p) => p.theme.font.weight.normal};
  color: ${(p) => (p.active ? p.theme.colors.primary.main : p.theme.colors.text.primary)};
`;



/**
 * Displays user suggestions
 */
const UserSuggestions = ({ pathname }) => {
  const { t } = useTranslation()
  const [{ auth }] = useStore();
  const history = useHistory()
  const { data, loading } = useQuery(GET_AVAILABLE_USERS, {
    variables: { userId: auth.user.id },
    pollInterval: 5000
  });

  const hideUserSuggestions = matchPath(pathname, {
    path: [Routes.MESSAGES, Routes.PEOPLE, Routes.EXPLORE, Routes.USER_PROFILE, Routes.SCHEDULES],
  });

  if (hideUserSuggestions) return null;

  if (loading) {
    return (
      <Root>
        <Loading />
      </Root>
    );
  }

  if (!data|| !data.getAvailableUsers.user) {
    return null;
  }

  return (
    <Root>
      <Avatar image={data.getAvailableUsers.user.image} size={ 80 }/>
      <Spacing bottom={'sm'} />
      <FullName>{data.getAvailableUsers.user.fullName} { t('isReadyToMeet')}</FullName>
      <Spacing bottom={'sm'} />
          <Button onClick={()=>history.push(generatePath(Routes.SPEEDNETWORKING))}>📹 Meet now</Button>
    </Root>
  );
};

UserSuggestions.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default UserSuggestions;
