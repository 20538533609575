import React from 'react';
import theme from 'theme';

/**
 * Post comment icon
 *
 * @param {string} width
 * @param {string} color
 */
export const PostCommentIcon = ({ width, color }) => {
  const DEFAULT_WIDTH = '25';
  const DEFAULT_COLOR = theme.colors.text.secondary;

  return (
    <svg viewBox="0 0 24 24"  width={width || DEFAULT_WIDTH}>
    <rect id="view-box" width="24" height="24" fill="none"/>
    <path id="Shape" d="M6.485,18.519a9.891,9.891,0,0,1-4.876.981c-.285,0-.584-.006-.887-.018a.739.739,0,0,1-.65-.432.738.738,0,0,1,.085-.775,11.192,11.192,0,0,0,2.072-3.787A9.751,9.751,0,1,1,10.751,19.5,9.661,9.661,0,0,1,6.485,18.519ZM6.808,17a8.247,8.247,0,1,0-3.139-3.015.75.75,0,0,1,.092.535A10.189,10.189,0,0,1,2.2,17.99a7.2,7.2,0,0,0,3.816-.947.745.745,0,0,1,.431-.136A.756.756,0,0,1,6.808,17Zm-.057-4.5a.75.75,0,0,1,0-1.5h7a.75.75,0,0,1,0,1.5Zm0-4a.75.75,0,0,1,0-1.5h5a.75.75,0,1,1,0,1.5Z" transform="translate(1.249 2.25)" fill={theme.colors[color] || DEFAULT_COLOR}/>
  </svg>
  );
};


