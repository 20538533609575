import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components'

const HideOnMobile = styled.div`
      
@media (max-width: ${(p) => p.theme.screen.md}) {
   display:none;
}
`

const LottieSocial = () => (
    <HideOnMobile>
  <Player
        autoplay
        bounce
    loop
    src="https://assets9.lottiefiles.com/packages/lf20_pon5lxe5.json"
    style={{ height: '500px', width: '500px' }}
  >
        </Player>
        </HideOnMobile>
)

export default LottieSocial