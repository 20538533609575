import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import i18n from 'i18n';
import SiteInfo from 'constants/SiteInfo.json';

const Root = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  height: 50px;
  width: 100%;
  font-size: ${(p) => p.theme.font.size.sm};
  z-index: 999999;
  border-top: 1px solid ${(p) => p.theme.colors.border.main};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.dark ? 'rgba(255,255,255,.2)' : 'transparent'};
  backdrop-filter: ${p => p.dark ? ' blur(6px)' : 'none'};
  color: ${p=>p.dark ? 'black' : 'white'};
  @media (max-width: ${(p) => p.theme.screen.sm}) {
      overflow-x: auto;
      overflow-y: hidden;
      padding-right: 100px;
      padding-left: 150px;
  }

`;

const ChooseLocale = styled.div`
  cursor: pointer;
  padding-left: 20px;
  font-weight: ${(p) => (p.selected ? 800 : 200)};
`;

/**
 * Displays left side bar
 */
const Footer = ({dark, absolute}) => {
  const [selectedLocale, setSelectedLocal] = useState();

  useEffect(() => {
    try {
      const persistedLocale = window.localStorage.getItem('locale');
      if (!!persistedLocale) {
        setSelectedLocal(persistedLocale);
        i18n.changeLanguage(persistedLocale);
      } else {
        setSelectedLocal(SiteInfo.defaultLocal);
        i18n.changeLanguage(SiteInfo.defaultLocal);
      }
    } catch (error) {console.error(error)}
  }, []);

  useEffect(() => {
    if (!!selectedLocale) {
      window.localStorage.setItem('locale', selectedLocale);
    }
  }, [selectedLocale]);

  return (
    <Root dark={dark} absolute={ absolute }>
      {SiteInfo.locales.map((l) => (
        <ChooseLocale
          onClick={() => {
            i18n.changeLanguage(l.value);
            setSelectedLocal(l.value);
          }}
          selected={selectedLocale === l.value}
          key={'chooseLocal' + l.value}
        >
          {l.name}
        </ChooseLocale>
      ))}
    </Root>
  );
};

export default Footer;
