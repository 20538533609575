import React, { useState } from 'react';
import styled from 'styled-components';
import toast, { Toaster } from 'react-hot-toast';
import { format } from 'date-fns';

import { Container, Content, Spacing } from 'components/Layout';
import { InputText, Button } from 'components/Form';

import { CREATE_TICKET } from 'graphql/ticket';
import { GET_ALL_SCHEDULES } from 'graphql/schedule';
import { GET_ROOMS } from 'graphql/room';

import { useStore } from 'store';
import { useMutation, useQuery } from '@apollo/client';


const Root = styled(Container)`
  margin-top: ${(p) => p.theme.spacing.lg};
`;

const FormContainer = styled.div`
  width: 100%;
  border-radius: ${(p) => p.theme.radius.sm};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  background-color: ${(p) => p.theme.colors.white};
  border-radius: ${(p) => p.theme.radius.lg};
  box-shadow: ${(p) => p.theme.shadows.inset};
  padding: ${(p) => p.theme.spacing.sm} ${(p) => p.theme.spacing.sm};
`
const Ticket = styled.div`
position: relative;
box-sizing: border-box;
width: 100%;
height: 110px;
margin: 50px auto 0;
padding: 20px;
border-radius: 10px;


`

const TicketContent = styled.div`
box-sizing: border-box;
height: 100%;
width: 100%;
border: 6px solid #D8D8D8;
font-size: 3rem;
  font-weight: 900;
  color: #C6DEDE;
`

const ErrorMessage = styled.span`
  font-size:12px;
  color:red;
`

const ListSchedules = styled.div`
  background-color: white;
  height: 300px;
  overflow-y: auto ;
  padding: 20px;
`

const ScheduleDetails = ({ schedule }) => (
  <div style={{
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 25%)",
    borderBottom: '1px solid rgba(0,0,0,.2)',
    fontSize: '12px',
    flexFlow: 'row wrap',
    textAlign: 'center',
    backgroundColor: schedule.confirmed ? '#3ab169' : 'white'
  }}>
    <div>{ schedule.sender.fullName}</div>
    <div>{ schedule.receiver.fullName}</div>
    <div>{format(schedule.schedule, 'MM/dd @ h:mm a')}</div>
    
    <div>{ schedule.confirmed ? 'Confirmed' : 'Not confirmed' }</div>
  
  </div>
)


const RoomDetails = ({ room }) => (
  <div style={{
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 25%)",
    borderBottom: '1px solid rgba(0,0,0,.2)',
    fontSize: '12px',
    flexFlow: 'row wrap',
    textAlign: 'center',
  }}>
    <div>{ room.pair[0].fullName}</div>
    <div>{room.pair[1].fullName}</div>
    <div>{format(parseInt(room.createdAt), 'MM/dd @ h:mm a')}</div>
  
  </div>
)





/**
 * Notifications page
 */
const Administration = () => {
  const [{ auth }] = useStore();
  const [newTicket, setNewTicket] = useState()
  const [email, setEmail] = useState('')
  const [error, setError] = useState()



  const [createTicket, { loading }] = useMutation(CREATE_TICKET);

  const { data:scheduleData } = useQuery(GET_ALL_SCHEDULES, {
    variables: {
      authUserId: auth.user.id,
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: 60*1000,
  });

  const { data: roomData } = useQuery(GET_ROOMS, {
    variables: {
      authUserId: auth.user.id
    }
  });

  const handleCreateTicket = async () => {
    setError('')
    const error = validate();
    if (error) {
      setError(error);
      return false;
    }


    try {
      const response = await createTicket({
        variables: {
          input: {
            authUserId: auth.user.id,
            email
         } },
      });

      
      if (response?.data?.createTicket) {
        setNewTicket(response?.data?.createTicket)
        toast('Ticket sent to '+email)
      }
      setEmail('')
    } catch (error) {
      setError(error?.graphQLErrors[0]?.message);
    }
  }

  const validate = () => {
    if (!email) {
      return 'Email field is required';
    }

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(String(email).toLowerCase())) {
      return 'Please enter a valid email address.';
    }

    return false;
  };


  return (
    <Content>
       <Toaster />
      <Root maxWidth="md">
        <FormContainer>
          <InputText
            placeholder="Attendee Email address"
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)} />
        {error && <ErrorMessage>{ error }</ErrorMessage>}
       
        <Spacing bottom={'sm'} />
          <Button disabled={ loading } onClick={()=>handleCreateTicket() }>Send invitation</Button>

        </FormContainer>
        <Spacing bottom={'lg'} />
        {
          newTicket?.number && <Ticket><TicketContent>{ newTicket.number }</TicketContent></Ticket>
        }
        
        <ListSchedules>
          {
            scheduleData?.getAllSchedules?.schedules &&
            <h1>{scheduleData.getAllSchedules.schedules.length} RDVs dont {scheduleData.getAllSchedules.schedules.filter(s => s.confirmed).length} confirmé(s)</h1>
          }
        {
            scheduleData?.getAllSchedules?.schedules &&
            scheduleData.getAllSchedules.schedules.map(s => <ScheduleDetails schedule={ s } key={ s.id }/>)
          }
          
         
        </ListSchedules>
        <Spacing top={'lg'} />

        <ListSchedules>

        {
            roomData?.getRooms?.count &&
            <h1>{ roomData?.getRooms?.count} Rencontre(s) SpeedNeworking</h1>
          }

        {
          roomData?.getRooms?.rooms &&  roomData.getRooms.rooms.map(r=><RoomDetails room={ r } key={ r.room }/>) 
        }

</ListSchedules>
      </Root>
    </Content>
  );
};

export default Administration;
