import React from 'react';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import SiteInfo from 'constants/SiteInfo.json';

const PureVideoChat = React.memo(({ roomId, width = '100%', height = '100%' }) => {
  return (
    <Iframe
      url={new URL(roomId, SiteInfo.videoChatEndpoint)}
      allow="microphone; camera; fullscreen"
      width={width}
      height={height}
      id="videoichatiframe"
      frameBorder="0"
      scrolling="no"
    />
  );
});

PureVideoChat.propTypes = {
    roomId: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string
};
  

export default PureVideoChat;
