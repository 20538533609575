import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { formatRelative } from 'date-fns';
import { isBeforeOrEqualDate } from 'utils/isBeforeOrEqual';
import { SCHEDULE_PENDING_WIDTH, HEADER_HEIGHT } from 'constants/Layout';
import AddScheduleBtn from './AddScheduleBtn';
import { Spacing } from 'components/Layout';
import Avatar from 'components/Avatar';
import * as Routes from 'routes';
import AddToCalendar from './AddToCalendar';

import 'react-tabs/style/react-tabs.css';

const StickySchedule = styled.div`
  display: none;
  background-color: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.border.main};
  position: fixed;
  top: ${HEADER_HEIGHT - 1}px;
  right: 0;
  height: 100%;
  width: ${() => SCHEDULE_PENDING_WIDTH}px;
  padding: ${(p) => p.theme.spacing.sm};
  overflow-y: auto;

  @media (min-width: ${(p) => p.theme.screen.md}) {
    display: block;
  }
`;

/**
 * Button
 */
export const Button = styled.button`
  height: 27px;
  cursor: pointer;
  outline: none;
  font-size: ${(p) => p.theme.font.size.xxs};
  font-weight: ${(p) => p.theme.font.weight.bold};
  transition: background-color 0.2s, border-color 0.1s;
  border-radius: ${(p) => p.theme.radius.md};
  color: ${(p) => (p.secondary ? p.theme.colors.black : p.theme.colors.white)};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  border: ${(p) => (p.secondary ? '1px solid black' : 0)};
  background-color: ${(p) => (p.secondary ? 'transparent' : p.theme.colors.primary.main)};
  margin-top: 10px;
  margin-right: 10px;

  &:active {
    border: 1px solid ${(p) => p.theme.colors.border.white};
    background-color: black;
  }
`;

export const A = styled.a`
display: block;
width: 100%;
  height: 27px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-size: ${(p) => p.theme.font.size.xxs};
  font-weight: ${(p) => p.theme.font.weight.bold};
  transition: background-color 0.2s, border-color 0.1s;
  border-radius: ${(p) => p.theme.radius.md};
  color: ${(p) => p.theme.colors.white};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  border: ${(p) => (p.secondary ? '1px solid black' : 0)};
  background-color: ${(p) => (p.secondary ? 'black' : p.theme.colors.primary.main)};
  margin-top: 10px;
  margin-right: 10px;

  &:active {
    border: 1px solid ${(p) => p.theme.colors.border.white};
    background-color: black;
  }
`;

const List = styled.ul`
  padding: 0;
  height: calc(100% - HEADER_HEIGHT);
  min-height: 300px;
  overflow-y: auto;
  width: 250px;
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ListItem = styled.div`
  text-decoration: none;
  color: ${(p) => p.theme.colors.text.primary};
  padding: ${(p) => p.theme.spacing.xs};
  box-shadow: 6px -6px 20px 0px rgb(0 0 0 / 10%), 1px -4px 0px 0px rgb(0 0 0 / 10%) inset;
  border-radius: ${(p) => p.theme.radius.lg};
  background-color: white;
  list-style-type: none;
  display: flex;
  flex-direction: column;

  margin-bottom: ${(p) => p.theme.spacing.sm};

  &:last-child {
    margin-bottom: 100px;
  }
  p {
    margin: 0;
  }
`;

const DateTime = styled.p`
  color: ${(p) => p.theme.colors.text};
  font-size: ${(p) => p.theme.font.size.xxs};
  margin-bottom: 5px;
  padding: 0;
`;

const MeetingText = styled.p`
  cursor: pointer;
  font-size: ${(p) => p.theme.font.size.xs};
  font-family: inherit;
  font-weight: ${(p) => p.theme.font.weight.bold};
  margin: 0;
  padding: 0;
`;

const ButtonGroup = styled.div`
margin-top: 10px;
margin-bottom: 10px;
`;

/**
 * Schedule list
 */

const SchedulesList = ({ schedules, authUser, openModal, confirmSchedule, deleteSchedule }) => {
  const { t } = useTranslation();
  const [pendingSchedules, setPendingSchedules] = useState([]);
  const [confirmedSchedules, setConfirmedSchedules] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (Array.isArray(schedules)) {
      const pendingSchedules = schedules.filter((f) => !f.confirmed).sort((a,b)=>b.schedule-a.schedule);
      const confirmedSchedules = schedules.filter((f) => f.confirmed).sort((a,b)=>b.schedule-a.schedule);

      setPendingSchedules(pendingSchedules);
      setConfirmedSchedules(confirmedSchedules);
    }
  }, [schedules]);

  function renderScheduleItem(scheduleItem) {
    if (!scheduleItem) return;
    const { id, sender, receiver, confirmed, schedule } = scheduleItem;
    return (
      <ListItem key={id + 'jalk'} style={isBeforeOrEqualDate(schedule) ? { opacity: 0.5 } : {}}>
        <div
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() =>
            history.push(
              generatePath(Routes.SCHEDULE, {
                id: id,
              })
            )
          }
        >
          <Avatar image={authUser === sender.id ? receiver.image : sender.image} />

          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
            <DateTime> {formatRelative(schedule, new Date())} </DateTime>

            <MeetingText>{ t('meetingwith')} {' '} {authUser === sender.id ? receiver.fullName : sender.fullName}</MeetingText>
            {authUser === sender.id && !confirmed && !isBeforeOrEqualDate(schedule) && (
              <div style={{ fontSize: '10px' }}>Waiting for confirmation</div>
            )}
          </div>
        </div>

        <div>
          <ButtonGroup>
            {authUser !== sender.id && !confirmed && <Button onClick={() => confirmSchedule(id)}>{ t('accept')}</Button>}

            {!isBeforeOrEqualDate(schedule) && (
              <Button secondary onClick={() => deleteSchedule(id)}>
                {t('cancel')}
              </Button>
            )}



           {!isBeforeOrEqualDate(schedule) && (
              <Button secondary onClick={() => history.push(
                generatePath(Routes.SCHEDULE, {
                  id: id,
                })
              )}>
                {t('edit')}
              </Button>
            )}
           
            
          </ButtonGroup>

          <AddToCalendar
            startTime={schedule}
            title={`Meeting with ${authUser === sender.id ? receiver.fullName : sender.fullName}`}
          />
         
        </div>
      </ListItem>
    );
  }

  return (
    <StickySchedule>
      Time Zone {window.Intl.DateTimeFormat().resolvedOptions().timeZone}
      <Spacing bottom="xs" />
      <AddScheduleBtn openModal={openModal} />
      <Spacing bottom="md" />
      {Array.isArray(schedules) && (
        <Tabs>
          <TabList>
            <Tab>{t('pending')}</Tab>
            <Tab>{t('confirmed')}</Tab>
          </TabList>

          <TabPanel>
            <List>{pendingSchedules.map((schedule) => renderScheduleItem(schedule))}</List>
          </TabPanel>
          <TabPanel>
            <List>{confirmedSchedules.map((schedule) => renderScheduleItem(schedule))}</List>
          </TabPanel>
        </Tabs>
      )}
    </StickySchedule>
  );
};

export default SchedulesList;

