import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useApolloClient, useQuery } from '@apollo/client';
import { FileIcon, defaultStyles } from 'react-file-icon';
import ReactTooltip from 'react-tooltip';

import { MAX_POST_IMAGE_SIZE } from 'constants/ImageSize';

import { UPLOAD_DOC, GET_DOCS, DELETE_DOC } from 'graphql/uploadDoc';

import { useStore } from 'store';

import { useGlobalMessage } from 'hooks/useGlobalMessage';

function getExtension(filename) {
  return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
}

const Root = styled.div`
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  border-bottom-left-radius: ${(p) => p.theme.radius.md};
  border-bottom-right-radius: ${(p) => p.theme.radius.md};
  padding: 10px;
`;

const Input = styled.input`
  display: none;
`;

const LabelContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
`;

const Label = styled.label`
  width: 200px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: ${(p) => p.theme.font.family};
  left: ${(p) => p.theme.spacing.sm};
  top: ${(p) => p.theme.spacing.sm};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.radius.sm};
  transition: background-color 0.4s;
  border: 1px solid ${(p) => p.theme.colors.border.main};
  background-color: ${(p) => p.theme.colors.primary.main};
  color: white;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    
  }
`;

const Icons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Icon = styled.div`
position: relative;
  width: 158px;
  margin: 16px;
  margin-right: 36px;
 

  a{
    text-decoration: none;
  font-family: ${(p) => p.theme.font.family};
  font-size: smaller;
  color: ${(p) => p.theme.colors.text.primary};
  }
`;

const DeleteFile = styled.div`
  position: absolute;
  top: 0;
  right: -20px;
  width:20px;
  height:20px;
  border-radius: 50%;
  background: red;
  color: white;
  font-size: 11px;
  align-items: center;
  justify-content: center;
  display:flex;
  cursor: pointer;
  &:hover{
    transform: scale(1.21);
  }
`

const FileName = styled.p`
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
`

/**
 * Displays and Updates user Cover image
 */
const ProfileCoverUpload = ({ userId }) => {
  const [{ auth }] = useStore();
  const client = useApolloClient();
  const [loading, setLoading] = useState(false)

  const { data } = useQuery(GET_DOCS, {
    variables: { authorId: userId },
  });

  const message = useGlobalMessage();

  const handleImageChange = async (e) => {
 
    const file = e.target.files[0];
    const name = file.name;
    e.target.value = '';

    if (!file) return;

    if (file.size >= MAX_POST_IMAGE_SIZE) {
      message.error(`File size should be less then ${MAX_POST_IMAGE_SIZE / 1000000}MB`);
      return;
    }

    setLoading(true)

    try {
      await client.mutate({
        mutation: UPLOAD_DOC,
        variables: {
          input: {
            authorId: auth.user.id,
            file: file,
            name,
            shareable: true
          },
        },
        refetchQueries: () => [{ query: GET_DOCS, variables: { authorId: auth.user.id } }],
      });
      setLoading(false)
    } catch (err) {
      message.error(err?.graphQLErrors[0]?.message);
      setLoading(false)
    }
  };

  async function handleDeleteDoc(id) {
    try {
      await client.mutate({
        mutation: DELETE_DOC,
        variables: {
          input: {
            id
          },
        },
        refetchQueries: () => [{ query: GET_DOCS, variables: { authorId: auth.user.id } }],
      });
     
    } catch (err) {
      message.error(err.graphQLErrors[0].message);
    }
  }

  return (
    <Root>
      <LabelContainer>
        <Input name="coverImage" type="file" id="coverImagetwo" onChange={handleImageChange} />
        {auth.user.id === userId && <Label htmlFor="coverImagetwo">{loading ? 'Uploading...' : 'Upload brochures'}</Label>}
      </LabelContainer>
      <Icons>
        {Array.isArray(data?.getUploadDocs?.uploads) &&
          data.getUploadDocs.uploads.map((file) => (
            
             <Icon data-tip={file.name} key={file.id}>
                {auth.user.id === userId && <DeleteFile onClick={() => handleDeleteDoc(file.id)}>X</DeleteFile>}
                <a  href={file.file} download={file.name}>
              <FileIcon extension={getExtension(file.name)} {...defaultStyles[getExtension(file.name)]} />
              <ReactTooltip />
              <FileName>{file.name}</FileName>
              </a>
            </Icon>
            
           
          ))}
      </Icons>
     
    </Root>
  );
};

ProfileCoverUpload.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default ProfileCoverUpload;
