import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components';
import {Button} from './Layout'

const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  h3{
    margin:0
  }
`;

const LottieSocial = ({userCount, pause, onResume}) => (
  <Container>
  
    <Player
      autoplay
      bounce
      loop
      src="https://assets4.lottiefiles.com/packages/lf20_ffkzpglj.json"
      style={{ height: '500px', width: '500px' }}
    ></Player>
    {
      !pause && <h3>Looking for contacts...</h3>
    }
    
    {
      pause &&   <Button
      fullWidth
      onClick={() => {
        onResume()
    }}
  >
    Resume
    </Button>
    }
    <p>{userCount} User{userCount>1 && 's' } is in the waiting list</p>
  </Container>
);

export default LottieSocial;
