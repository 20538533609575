import React from 'react';
import theme from 'theme';

/**
 * Plus (+) icon
 *
 * @param {string} width
 * @param {string} color
 */
export const PlusIcon = ({ width, color }) => {
  const DEFAULT_WIDTH = '14';
  const DEFAULT_COLOR = theme.colors.text.secondary;

  return (
 <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0" enableBackground="new 0 0 58 58"
      version="1.1"
      width={width || DEFAULT_WIDTH}  viewBox="0 0 58 58"
  xmlSpace="preserve"
  fill={theme.colors[color] || DEFAULT_COLOR}
    >
      <path d="M51 31.979V1.5H0v46a1 1 0 001 1h32.003c1.963 4.693 6.6 8 11.997 8 7.168 0 13-5.832 13-13 0-5.003-2.846-9.349-7-11.521zM35 3.5h14v8H35v-8zm-16 0h14v8H19v-8zm-17 0h15v8H2v-8zm30.359 43H2v-33h47v17.636c-.195-.063-.396-.114-.595-.168-.186-.051-.372-.102-.559-.144a12.933 12.933 0 00-1.246-.215c-.137-.017-.273-.036-.411-.049-.394-.036-.791-.06-1.19-.06a12.942 12.942 0 00-9.018 3.658c.035-.323.076-.644.076-.974v-7.345c0-2.042-.712-3.773-2.134-5.196-1.423-1.423-3.149-2.134-5.18-2.134s-3.757.711-5.179 2.134c-1.423 1.423-2.134 3.148-2.134 5.179s.711 3.757 2.134 5.18c1.422 1.423 3.183 2.134 5.282 2.134s3.871-.78 5.316-2.341v2.148c0 2.157-.631 3.791-1.893 4.904-1.262 1.112-2.753 1.669-4.474 1.669h-.74v1.893h.603c1.537 0 2.879-.27 4.026-.809a8.26 8.26 0 00.755-.405A13.056 13.056 0 0032 43.5c0 .493.033.977.087 1.456.009.075.024.149.034.224.056.428.131.849.227 1.262.005.019.006.039.011.058zm1.805-20.712c0 1.549-.521 2.845-1.565 3.889s-2.323 1.565-3.837 1.565c-1.515 0-2.8-.521-3.854-1.565-1.056-1.044-1.583-2.323-1.583-3.837 0-1.515.527-2.799 1.583-3.854 1.055-1.055 2.334-1.583 3.837-1.583s2.782.511 3.837 1.531c1.055 1.021 1.582 2.305 1.582 3.854zM45 54.5c-4.787 0-8.859-3.08-10.367-7.358a11.327 11.327 0 01-.249-.806l-.045-.173a11.15 11.15 0 01-.156-.716c-.024-.133-.042-.267-.061-.401-.026-.18-.054-.36-.071-.541A11.12 11.12 0 0134 43.5c0-6.065 4.935-11 11-11 .389 0 .776.021 1.16.063.062.007.121.021.183.028.326.041.652.089.973.159.042.009.082.022.123.032.342.079.681.17 1.016.282.025.008.048.018.073.027.343.118.683.252 1.018.404C53.347 35.229 56 39.057 56 43.5c0 6.065-4.935 11-11 11z"></path>
      <path d="M51 42.5h-5v-5a1 1 0 10-2 0v5h-5a1 1 0 100 2h5v5a1 1 0 102 0v-5h5a1 1 0 100-2z"></path>
      <path d="M11 21.5L11 23.5 16.058 20.5 16.058 41.578 18.058 41.578 18.058 18.5 16.058 18.5z"></path>
    </svg>
    
  );
};


