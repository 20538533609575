import React from 'react'
import Styled from 'styled-components'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import { useHistory, generatePath } from 'react-router-dom'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import zh from 'date-fns/locale/zh-CN'
import it from 'date-fns/locale/it'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import en from 'date-fns/locale/en-US'
import pt from 'date-fns/locale/pt'
import * as Routes from 'routes'

import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = {
  'en-US': en,
    zh,
    it,
    es,
    fr,
    pt
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Container = Styled.div`
.rbc-toolbar-label{
 margin: 0;
 padding:0
}

.rbc-event{
  border-radius: 0;
  font-family: inherit;
  background-color: #5571e6
}
.rbc-month-view{
  box-shadow: ${(p) => p.theme.shadows.inset}
}
.rbc-calendar{
  width: 50vw;
 font-family: ${(p) => p.theme.font.family};
  padding-right: 20px;
  
  @media (max-width: ${(p) => p.theme.screen.sm}) {
    width:95vw;
  }
 
}

.rbc-toolbar button{
 width:auto;
 margin: 0;
 padding: 15px;
 border-radius: 50px;
 background-color: white;
 border:0;
 margin-right:1px;

 @media (max-width: ${(p) => p.theme.screen.md}) {
    display:none !important;
  }


}
.rbc-month-view{
  background-color: white;
  border:0;
  border-radius:18px
  
}

.rbc-agenda-view{
  background-color: white
}
.rbc-month-header{
  padding-top:20px;
  padding-bottom:20px
  border:0
}

.rbc-header + .rbc-header{
  border: 0
  padding-top:20px;
  padding-bottom:20px
}

`;

const CustomBarContainer = Styled.div`
  display: flex;
  justify-content:space-between;
  margin-bottom: 10px;
  margin-right: '10px';
  font-family:  ${(p) => p.theme.font.family};

`;



class CustomToolbar extends React.Component {
  render() {
    let {
      localizer: { messages },
      label,
    } = this.props;
    return (
      <CustomBarContainer>
        <div>{label}</div>
        <div>{this.viewNamesGroup(messages)}</div>
      </CustomBarContainer>
    );
  }
  navigate = (action) => {
    this.props.onNavigate(action);
  };

  view = (view) => {
    this.props.onView(view);
  };

  viewNamesGroup(messages) {
    let viewNames = this.props.views;
    const view = this.props.view;

    if (viewNames.length > 1) {
      return viewNames.map((name) => (
        <button
          type="button"
          style={{
            backgroundColor: view === name ? '#f9dd38' : 'white',
            border: 0,
            marginLeft: '5px',
            cursor: 'pointer',
            fontFamily: 'inherit',
            fontWeight: 100,
            fontSize: '12px',
          }}
          key={name}
          className={{ 'rbc-active': view === name }}
          onClick={this.view.bind(null, name)}
        >
          {messages[name]}
        </button>
      ));
    }
  }
}

const MyCalendar = ({ events }) => {
  const history = useHistory()

  return (
  <Container>
    <Calendar
      localizer={localizer}
        events={events}
        culture = {window.localStorage.getItem('locale') || 'en'}
      startAccessor="start"
      endAccessor="end"
        onSelectEvent={event => {
        
        history.push(generatePath(Routes.SCHEDULE, {
        id: event.id,
      }))
        
      }}
      components={{
        toolbar: CustomToolbar,
      }}
      style={{
        height: 500,
      }}
    />
  </Container>
)};

export default MyCalendar;
