/**
 * Available Notification types
 */

export const NotificationType = {
  LIKE: 'LIKE',
  FOLLOW: 'FOLLOW',
  COMMENT: 'COMMENT',
  SCHEDULE: 'SCHEDULE'
};
