import React from 'react';
import styled from 'styled-components';
import AudioVideoChat from 'components/AudioVideoChat'
import { MoveIcon, CloseIcon } from 'components/icons'


const screenWidth = window.screen.width
const screenHeight = window.screen.height
const isSmallScreen = screenWidth <= 1007


const clamp = function(n) {
    return Math.min(
        Math.max(n, 0),
        // container width - window width
        1110 - 400);
};

const Root = styled.div`
    z-index: 999999999;
    background-color: white;
    width: 500px;
    height: 400px;
    position: fixed;
    box-shadow: ${(p) => p.theme.shadows.inset};
      
    @media (max-width: ${(p) => p.theme.screen.md}) {
        position:fixed;
        left:0;
        bottom:0;
        width: 100%;
        max-width:100%;
        height:100%

    }
`
const Bar = styled.div`
      
`
const Close = styled.span`
 cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: rgba(255,255,255,.5);
      width: 32px;
      height: 32px;
      display: flex;
      justify-content:center;
      svg{
        margin: 0 auto;
        text-align:center;
        width:70%
    }
`

const MoveWindow = styled.div`
    cursor: move;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items:center;
    top: 10px;
    left: 10px;
    background-color: rgba(255,255,255,.5);
    width: 32px;
    height: 32px;
    text-align:center;
    svg{
        margin: 0 auto;
        text-align:center;
        width:80%
    }
  
`


export default class Window extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDragging: false,
            xDiff: 0,
            yDiff: 0,
            x: isSmallScreen ? 0 : 200,
            y: isSmallScreen ? 150 : 200,
        };

        this.onDragStart = this.onDragStart.bind(this);
    }
    componentDidMount() {
        document.addEventListener('pointermove', this.onDrag.bind(this));
        document.addEventListener('pointerup', this.onDragStop.bind(this));
    }
    render() {
        if (this.props.isHidden) {
            return null;
        }

        const style = {
            transform: `translate(${this.state.x}px, ${this.state.y}px)`
        };

        return (
            <Root style={style}>
                <Bar >
                    <Close
                          onClick={this.props.onClose}>
                        <CloseIcon width={24} color={ 'black' }/>
                    </Close>

                    <MoveWindow
                         onMouseDown={this.onDragStart}>
                       <MoveIcon />
                    </MoveWindow>

                </Bar>

                <div className="window-body">
                    
                    <AudioVideoChat roomId={this.props.roomId}
                         width="100%"
                        height={isSmallScreen ? screenHeight + "px" : "400px"} />
                   
                </div>
            </Root>
        );

    }

    onDragStart(e) {
        this.setState({
            isDragging: true,
            xDiff: e.pageX - this.state.x,
            yDiff: e.pageY - this.state.y
        });
    }
    onDragStop() {
        this.setState({isDragging: false});
    }
    onDrag(e) {
        if (this.state.isDragging) {
            this.setState({
                x: clamp(e.pageX - this.state.xDiff),
                y: clamp(e.pageY - this.state.yDiff)
            });
        }
    }
}
