import { createGlobalStyle } from 'styled-components';
import theme from 'theme';

/**
 * Global styles for the application
 */
export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    font-family: 'Rubik', Segoe UI Historic, Segoe UI, Helvetica, Arial, system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif;
    color: ${theme.colors.text.primary};
    background-color: ${theme.colors.body};
  overflow-x: hidden;

  }

  #root {
    position: relative;
    min-height: 100vh;
  }
`;
