import React, { useState, useEffect } from 'react';
import { add } from 'date-fns';
import styled from 'styled-components';

import { Container } from 'components/Layout';
import Head from 'components/Head';
import Modal from 'components/Modal';
import AddSchedule from './AddSchedule';
import NextMeeting from './NextMeeting'

import SchedulesList from './ScheduleList';
import Calendar from './CalendarView';

import { GET_SCHEDULES, CONFIRM_SCHEDULE, DELETE_SCHEDULE } from 'graphql/schedule';
import { useStore } from 'store';
import { useQuery, useMutation } from '@apollo/client';
import { LONG_POLL_INTERVAL } from 'constants/PollInterval';

const Root = styled(Container)`
  margin-top: ${(p) => p.theme.spacing.lg};

  @media (min-width: ${(p) => p.theme.screen.lg}) {
    margin-left: ${(p) => p.theme.spacing.lg};
    padding: 0;
  }
`;

const ScheduleContainer = styled.div`
  display: flex;
  width: calc(100% - 250px);
  display: block;

  @media (max-width: ${(p) => p.theme.screen.md}) {
    width: 100%;
  }
`;




/**
 * Schedule page
 */

const Schedule = () => {
  const [{ auth }] = useStore();
  const [calendarData, setCalendarData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, loading, refetch } = useQuery(GET_SCHEDULES, {
    variables: {
      authUserId: auth.user.id,
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: LONG_POLL_INTERVAL,
  });

  const [deleteSchedule] = useMutation(DELETE_SCHEDULE, {
    refetchQueries: [
      {
        query: GET_SCHEDULES,
        variables: {
          authUserId: auth.user.id,
        },
      },
    ],
  });

  const [confirmSchedule] = useMutation(CONFIRM_SCHEDULE, {
    refetchQueries: [
      {
        query: GET_SCHEDULES,
        variables: {
          authUserId: auth.user.id,
        },
      },
    ],
  });

  const handleDeleteSchedule = async (scheduleId) => {
    try {
      await deleteSchedule({
        variables: {
          input: {
            id: scheduleId,
            authUserId: auth.user.id,
          },
        },
      });
    } catch (error) {}
  };

  const handleConfirmSchedule = async (scheduleId) => {
    try {
      await confirmSchedule({
        variables: {
          input: {
            id: scheduleId,
            authUserId: auth.user.id,
          },
        },
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (data?.getSchedules.schedules) {
      const filterCalData = data.getSchedules.schedules.filter((f) => f.confirmed);

      const formatCalData = filterCalData.map((s) => ({
        id: s.id,
        title: s.sender.id === auth.user.id ? s.receiver.fullName : s.sender.fullName,
        start: new Date(s.schedule),
        end: add(s.schedule, { minutes: 30 }),
      }));

      setCalendarData(formatCalData);
    }
  }, [data, auth.user.id]);

  useEffect(() => {
    if (typeof refetch === 'function') {
      refetch();
    }
  }, [refetch]);
  
  return (
    <Root maxWidth="md">
      <Head title="My schedule" />

      <ScheduleContainer>
        <NextMeeting />
        <Calendar events={calendarData} />
      </ScheduleContainer>

      <SchedulesList
        openModal={() => setIsModalOpen(true)}
        authUser={auth.user.id}
        schedules={data?.getSchedules.schedules}
        loading={loading}
        deleteSchedule={handleDeleteSchedule}
        confirmSchedule={handleConfirmSchedule}
      />

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddSchedule onSubmit={() => setIsModalOpen(false)} />
      </Modal>
    </Root>
  );
};

export default Schedule;
