import zh from 'date-fns/locale/zh-CN'
import it from 'date-fns/locale/it'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import en from 'date-fns/locale/en-US'
import pt from 'date-fns/locale/pt'
  
const dateFnsLocal = () => {
  
  const dateLocales = {
    zh,
    it,
    es,
    fr,
    en,
    pt
  };

  const persistedLocale = window.localStorage.getItem('locale');

  if (Object.keys(dateLocales).indexOf(persistedLocale) > -1) {
    return dateLocales[persistedLocale];
  } else {
    return dateLocales['en'];
  }

  
};

export default dateFnsLocal;
