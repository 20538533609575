import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Spacing, Container } from 'components/Layout';
import { H1, Error } from 'components/Text';
import { InputText, Button } from 'components/Form';
import Head from 'components/Head';
import countryList from 'utils/countryList'

import { SIGN_UP } from 'graphql/user';

import * as Routes from 'routes';

const countryOptions = Object.keys(countryList)
  .map(f=>  {return{ value:  f, label: countryList[f] }})

const Root = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 60px;

  @media (min-width: ${(p) => p.theme.screen.md}) {
    justify-content: space-between;
    margin-top: 120px;
  }
`;

const Welcome = styled.div`
  display: none;
  flex-direction: column;
  color: ${(p) => p.theme.colors.white};
  max-width: ${(p) => p.theme.screen.xs};

  @media (min-width: ${(p) => p.theme.screen.md}) {
    display: flex;
  }
`;

const Heading = styled(H1)`
  margin-bottom: ${(p) => p.theme.spacing.sm};
  max-width: 80%;
`;

const Form = styled.div`
  padding: ${(p) => p.theme.spacing.md};
  border-radius: ${(p) => p.theme.radius.sm};
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.shadows.inset};
  width: 100%;

  @media (min-width: ${(p) => p.theme.screen.sm}) {
    width: 500px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const Previous = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const ShowSection = styled.div`
  display: ${(p) => (p.when ? 'block' : 'none')};
`;

/**
 * Sign Up page
 */
const SignUp = ({ history, refetch }) => {
  const [error, setError] = useState('');
  const [section, setSection] = useState(0);
  const [values, setValues] = useState({
    fullName: '',
    username: '',
    company: '',
    position: '',
    email: '',
    password: '',
    inviteCode: '',
    country: '',
    timezone: window.Intl.DateTimeFormat().resolvedOptions().timeZone
  });
  const [signup, { loading }] = useMutation(SIGN_UP);
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSelectCountryChange = ({value}) => {
    setValues({ ...values, country: value });
  };

  const validate = () => {
    if (!fullName || !email || !username || !password || !inviteCode) {
      return 'All fields are required';
    }

    if (fullName.length > 50) {
      return 'Full name no more than 50 characters';
    }

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(String(email).toLowerCase())) {
      return 'Enter a valid email address.';
    }

    const usernameRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/;
    if (!usernameRegex.test(username)) {
      return 'Usernames can only use letters, numbers, underscores and periods';
    } else if (username.length > 20) {
      return 'Username no more than 50 characters';
    }

    if (password.length < 6) {
      return 'Password min 6 characters';
    }

    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = validate();
    if (error) {
      setError(error);
      return false;
    }

    try {
      const response = await signup({
        variables: { input: { fullName, email, password, username, company, position, inviteCode, timezone, country } },
      });
      localStorage.setItem('token', response.data.signup.token);
      await refetch();
      history.push(Routes.HOME);
      window.location.reload();
    } catch (error) {
      if (error && error.graphQLErrors) {
        setError(error?.graphQLErrors[0]?.message);
      }
    }
  };

  const { fullName, email, password, username, company, position, inviteCode, timezone, country } = values;
  return (
    <Root maxWidth="lg">
      <Head />

      <Welcome>
        <div>
          <Heading color="white">{t('eventname')}</Heading>
        </div>

        <p>{t('eventsub1')}</p>
        <p>{t('eventsub2')}</p>
        <p>{t('eventsub3')}</p>
      </Welcome>

      <Form>
        <Spacing bottom="md">
          <H1>{t('createaccount')}</H1>
        </Spacing>

        <form onSubmit={(e) => handleSubmit(e, signup)}>
          <ShowSection when={section === 0}>
            <>
              <Spacing top="xs" bottom="xs" />
              <InputText
                type="text"
                name="inviteCode"
                values={inviteCode}
                onChange={handleChange}
                placeholder={t('invitecode')}
                autocomplete="off"
              />

              <Spacing top="sm" />
              <Button
                disabled={!inviteCode}
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setSection(1);
                }}
              >
                {t('next')}
              </Button>
            </>
          </ShowSection>

          <ShowSection when={section === 1}>
            <>
              <Spacing top="xs" bottom="xs" />
              <Spacing top="xs" bottom="xs">
                <InputText
                  type="text"
                  name="fullName"
                  values={fullName}
                  onChange={handleChange}
                  placeholder={t('fullname')}
                />
                <Spacing top="xs" bottom="xs" />
                <Select
            placeholder={countryList[country]}
            value={country}
            onChange={handleSelectCountryChange}
            options={countryOptions}
                />
                <Spacing top="xs" bottom="xs" />
                <InputText
                  type="text"
                  name="company"
                  values={company}
                  onChange={handleChange}
                  placeholder={t('company')}
                />
                <Spacing top="xs" bottom="xs" />
                <InputText
                  type="text"
                  name="position"
                  values={position}
                  onChange={handleChange}
                  placeholder={t('position')}
                />
 <Spacing top="xs" bottom="xs" />
                <InputText type="email" name="email" values={email} onChange={handleChange} placeholder={t('email')} />
              </Spacing>
              <InputText
                type="text"
                name="username"
                values={username}
                onChange={handleChange}
                placeholder={t('username')}
              />
              <Spacing top="xs" bottom="xs">
                <InputText
                  type="password"
                  name="password"
                  values={password}
                  onChange={handleChange}
                  placeholder={t('password')}
                />
              </Spacing>
              <Spacing top="sm" />

              <ButtonContainer>
                <Previous
                  onClick={(e) => {
                    e.preventDefault();
                    setSection(0);
                  }}
                >
                  {t('previous')}
                </Previous>

                <Button size="large" disabled={loading}>
                  {t('signup')}
                </Button>
              </ButtonContainer>
            </>
          </ShowSection>

          {error && (
            <Spacing bottom="sm" top="sm">
              <Error>{error}</Error>
            </Spacing>
          )}
        </form>
      </Form>
    </Root>
  );
};

SignUp.propTypes = {
  history: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default withRouter(SignUp);
