import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import AudioVideoChat from 'components/AudioVideoChat';
import Head from 'components/Head';
import MessagesChat from 'pages/Messages/MessagesChat';

const ChatContainer = styled.div`
  position: fixed;
  min-width: 348px;
  height:100%;
  max-height:calc(100% - 50px);
  right: 10px;
  bottom: 0;
  background-color: rgba(255,255,255,.2);
  backdrop-filter: blur(10px);
  box-shadow: ${(p) => p.theme.shadows.inset};
  overflow: hidden;
  
  @media (max-width: ${(p) => p.theme.screen.sm}) {
   display:none;
  }
`;

const VideoContainer = styled.div`
  width: calc(100% - 248px);
  height:100%;
  max-height: 500px;
  box-shadow: ${(p) => p.theme.shadows.inset};
  overflow: hidden;
  border-radius:40px;
  border: 2px solid white;
  
  @media (max-width: ${(p) => p.theme.screen.sm}) {
    width: 100%;
    max-height: 90%;
  }
`;




/**
 * Room page
 */
const Room = ({ roomId, userId, auth }) => {
  const [fakeMatch, setFakeMatch] = useState();
  const [appError, setAppError] = useState();

  useEffect(() => {
    if (userId === auth.user.id) {
      setAppError('auth and guest user must have different ids');
    }
  }, [auth, userId]);

  useEffect(() => {
    if (userId && roomId) {
      let matchAsProps = {};
      matchAsProps.params = {};
      matchAsProps.params.userId = userId;
      matchAsProps.params.roomId = roomId.trim();
      setFakeMatch(matchAsProps);
    }
  }, [roomId, userId]);

  return (
    <>
      {!appError && roomId && (
        <>
          <Head title="💬 ☎️ P2P Video Chat" />

          <VideoContainer>
            <AudioVideoChat roomId={roomId.trim()}/>
          </VideoContainer>
         
         

          {userId && fakeMatch && fakeMatch.params && auth && auth.user && (
            <ChatContainer>
              <MessagesChat authUser={auth.user} match={fakeMatch} hideCallWindow={true} />
            </ChatContainer>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(Room);
