/**
 * Layout size constants
 */

export const SIDEBAR_DESKTOP_WIDTH = 180;

export const SIDEBAR_MOBILE_WIDTH = 240;

export const USER_SUGGESTIONS_WIDTH = 240;

export const SCHEDULE_PENDING_WIDTH = 300;

export const HEADER_HEIGHT = 60;
