import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { A } from 'components/Text';
import { Spacing } from 'components/Layout';
import Follow from 'components/Follow';
import theme from 'theme';
import { countryCodeToFlag } from 'utils/countryList'

import cdnFormatter from 'utils/cdnFormatter'

import Modal from 'components/Modal';
import AddSchedule from '../Schedule/AddSchedule';

import * as Routes from 'routes';

const Root = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 330px;
  background-color: white;
  padding: ${(p) => p.theme.spacing.sm};
  border-radius: ${(p) => p.theme.radius.sm};
  transition: border-color 0.1s;
  box-shadow: ${(p) => p.theme.shadows.inset};
  text-align: center;
`;

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const InitialLetters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.white};
  font-size: ${(p) => p.theme.font.size.lg};
  background-color: ${(p) => p.color};
`;

const FullName = styled.span`
  max-width: 200px;
  font-weight: ${(p) => p.theme.font.weight.bold};
`;

const UserName = styled.span`
  font-size: ${(p) => p.theme.font.size.xs};
`;

const BookSchedule = styled.div`
margin-top: 17px;
  height: 27px;
  width: 100%;
  min-width: 170px;
  cursor: pointer;
  outline: none;
  font-size: 12px;
  font-weight: ${(p) => p.theme.font.weight.bold};
  transition: background-color 0.2s, border-color 0.1s;
  border-radius: ${(p) => p.theme.radius.sm};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};

  background-color: 'transparent';
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color:  ${(p) => p.theme.colors.primary.main};
    color: white;
    box-shadow:none;
  }

`

const Country = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

/**
 * Card component for rendering user info, meant to be used in Peoples page
 */
const PeopleCard = ({ user }) => {
  const { t } = useTranslation()
  const [color, setColor] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { fullName, username, image, company, country } = user;
  
  useEffect(() => {
    const { primary, secondary, success, error } = theme.colors;
    const colors = [primary.main, secondary.main, success, error.main];
    const randomColor = Math.floor(Math.random() * colors.length);
    setColor(colors[randomColor]);
  }, []);

  const splitFullName = () => {
    // If a fullName contains more word than two, take first two word
    const splitWords = fullName.split(' ').slice(0, 2).join(' ');

    // Take only first letters from split words
    const firstLetters = splitWords
      .split(' ')
      .map((a) => a.charAt(0))
      .join(' ');

    return firstLetters;
  };

  return (
    <Root>
    
      <A to={generatePath(Routes.USER_PROFILE, { username })}>
        <ImageContainer>
          {image ? <Image src={cdnFormatter(image)} /> : <InitialLetters color={color}>{splitFullName()}</InitialLetters>}
        </ImageContainer>
      </A>

      <Country> {country &&
        
        <img src={countryCodeToFlag(country)} alt={country} width={'40px'} height={ 'auto' }/>
      
      }</Country>

      <Spacing top="sm" bottom="xs">
        <A to={generatePath(Routes.USER_PROFILE, { username })}>
          <FullName>{fullName}</FullName>
        </A>
        
      </Spacing>
      

      
        <UserName>@{company || '_'}</UserName>
      
      <BookSchedule onClick={() => setIsModalOpen(true)}>📅 { t('bookmeeting')}</BookSchedule>
      <Spacing top="lg" />

      <Follow user={user} />
    

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddSchedule onSubmit={() => setIsModalOpen(false)} user={ user }/>
      </Modal>
    </Root>
  );
};

PeopleCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default PeopleCard;
