import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

import { GET_ONLINE_USERS } from 'graphql/user';
import { LONG_POLL_INTERVAL } from 'constants/PollInterval';

import { useStore } from 'store';

import { Container } from 'components/Layout';
import Avatar from 'components/Avatar';

const Root = styled(Container)`
  border: 0;
  border-radius: ${(p) => p.theme.radius.lg};
  box-shadow: ${(p) => p.theme.shadows.inset};
`;

const Wrapper = styled.div`
  display: flex;
  overflow-x: auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${(p) => p.theme.spacing.sm} 0;

  overflow: auto;

  ::-webkit-scrollbar {
    height: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  > div {
    margin-left: 10px;
  }
`;

export const CreateRoom = styled(Link)`
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 150px;
  outline: none;
  font-size: ${(p) => p.theme.font.size.xxs};
  font-weight: ${(p) => p.theme.font.weight.regular};
  transition: background-color 0.2s, border-color 0.1s;
  border-radius: ${(p) => p.theme.radius.md};
  text-decoration: none;


  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  border: ${(p) => `1px solid ${p.theme.colors.border.main}`};
  background-color: ${(p) => p.theme.colors.primary.light};
  color: white;

  &:hover {
    transform: scale(.98)
  }
`;

/**
 * Component for rendering follow button
 */
const uniqueId = uuidv4()

const OnlineUsers = () => {
  const { t } = useTranslation()
  const [{ auth }] = useStore();
  const history = useHistory();

  const variables = {
    userId: auth.user.id,
  };

  const { data } = useQuery(GET_ONLINE_USERS, {
    variables,
    notifyOnNetworkStatusChange: true,
    pollInterval: LONG_POLL_INTERVAL,
  });

  return (

      
        <Root color="white" radius="sm" padding="sm">
          <Wrapper>
            <CreateRoom to={`room/${uniqueId}`}>
              💬 {t('createroom')}
            </CreateRoom>
            
            {data?.getOnlineUsers?.users &&
              data.getOnlineUsers.users.map((user, i) => (
                <RenderUser user={user} key={user.id + '-isOnline'} onClick={() => history.push(`/${user.username}`)} />
              ))}
          </Wrapper>
        </Root>


  );
};

const RenderUser = ({ user, onClick = () => {} }) => {
  return (
    <div data-tip={user.fullName} onClick={onClick} style={{ cursor: 'pointer' }}>
      <Avatar image={user.image} isOnline={user.isOnline} />
      <ReactTooltip />
    </div>
  );
};

OnlineUsers.propTypes = {
  user: PropTypes.object,
};

export default OnlineUsers;
