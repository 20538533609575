import { gql } from '@apollo/client';


/**
 * Creates a schedule
 */
export const CREATE_SCHEDULE = gql`
  mutation($input: CreateScheduleInput!) {
    createSchedule(input: $input) {
      id
      receiver {
        id
        username
        fullName
        image
        createdAt
      }
      sender {
        id
        username
        fullName
        image
        createdAt
      }
      schedule
    }
  }
`;




export const GET_SCHEDULES = gql`
  query($authUserId: ID!) {
    getSchedules(authUserId: $authUserId) {
      schedules{
      id
      receiver {
        id
        username
        fullName
        image
        createdAt
      }
      sender {
        id
        username
        fullName
        image
        createdAt
      }
      schedule
      confirmed
      createdAt
      }
      
    }
  }
`;



export const GET_SCHEDULE = gql`
  query($scheduleId: ID!) {
    getSchedule(id: $scheduleId) {
      id
      receiver {
        id
        username
        fullName
        image
        createdAt
      }
      sender {
        id
        username
        fullName
        image
        createdAt
      }
      schedule
      confirmed
      createdAt
      }
  }
`;


export const GET_ALL_SCHEDULES = gql`
  query($authUserId: ID!) {
    getAllSchedules(authUserId: $authUserId) {
      schedules{
      id
      receiver {
        id
        username
        fullName
        image
        createdAt
      }
      sender {
        id
        username
        fullName
        image
        createdAt
      }
      schedule
      confirmed
      createdAt
      }
      
    }
  }
`;



/**
 * Deletes a schedule
 */
export const DELETE_SCHEDULE = gql`
  mutation($input: DeletScheduleInput!) {
    deleteSchedule(input: $input) {
      id
    }
  }
`;



/**
 * confirm a schedule
 */
 export const CONFIRM_SCHEDULE = gql`
 mutation($input: ConfirmScheduleInput!) {
  confirmSchedule(input: $input) {
     id
   }
 }
`;



/**
 * update a schedule
 */
 export const UPDATE_SCHEDULE = gql`
 mutation($input: UpdateScheduleInput!) {
    updateSchedule(input: $input) {
     receiver {
       id
       username
       fullName
       image
       createdAt
     }
     sender {
       id
       username
       fullName
       image
       createdAt
     }
     schedule
     id
   }
 }
`;
