import { gql } from '@apollo/client';

/**
 * Creates a ticket
 */
export const CREATE_TICKET = gql`
  mutation($input: createTicketInput!) {
    createTicket(input: $input) {
      number
      used
      sentTo
    }
  }
`;