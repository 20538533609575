import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, generatePath, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useStore } from 'store';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import { formatDistance, format } from 'date-fns';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';


import * as Routes from 'routes';
import Skeleton from 'components/Skeleton';
import { Container, Content, Spacing } from 'components/Layout';
import Empty from 'components/Empty';
import { A, H2 } from 'components/Text';
import AddToCalendar from './AddToCalendar';
import { CameraIcon } from 'components/icons';

import { GET_SCHEDULE, CONFIRM_SCHEDULE, DELETE_SCHEDULE, UPDATE_SCHEDULE } from 'graphql/schedule';

import 'react-datepicker/dist/react-datepicker.css';
import './datePickerCustomStyling.css';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  z-index: ${(p) => p.theme.zIndex.xl};
  padding: ${(p) => p.theme.spacing.sm};
  border-radius: ${(p) => p.theme.radius.lg};

  @keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    flex-direction: column;
    > div {
      margin-bottom: 10px;
    }
  }
`;

const Root = styled(Container)`
  margin-top: ${(p) => p.theme.spacing.lg};
`;

/**
 * Button
 */
export const Button = styled.button`
  height: 27px;
  cursor: pointer;
  outline: none;
  font-size: ${(p) => p.theme.font.size.xxs};
  font-weight: ${(p) => p.theme.font.weight.bold};
  transition: background-color 0.2s, border-color 0.1s;
  border-radius: ${(p) => p.theme.radius.md};
  color: ${(p) => (p.secondary ? p.theme.colors.black : p.theme.colors.white)};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  border: ${(p) => (p.secondary ? '1px solid grey' : 0)};
  background-color: ${(p) => (p.secondary ? 'transparent' : p.theme.colors.primary.main)};
  margin-top: 10px;
  margin-right: 10px;

  &:active {
    border: 1px solid ${(p) => p.theme.colors.border.white};
    background-color: black;
  }
`;

export const Accept = styled(Button)`
  animation: pulse 2s infinite;
`;

export const UpdateBtn = styled(Button)`
  animation: pulse 2s infinite;
`;

export const MettingButton = styled(Link)`
  text-decoration: none;
  display: flex;
  height: 57px;
  cursor: pointer;
  outline: none;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: ${(p) => p.theme.font.size.xxs};
  font-weight: ${(p) => p.theme.font.weight.bold};
  transition: background-color 0.2s, border-color 0.1s;
  border-radius: ${(p) => p.theme.radius.md};
  color: ${(p) => (p.secondary ? p.theme.colors.black : p.theme.colors.white)};
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  border: ${(p) => (p.secondary ? '1px solid black' : 0)};
  background-color: ${(p) => (p.secondary ? 'transparent' : p.theme.colors.primary.main)};
  margin-top: 10px;
  margin-right: 10px;

  &:active {
    border: 1px solid ${(p) => p.theme.colors.border.white};
    background-color: black;
  }
`;

const WaintingForConfirmation = styled.div`
  position: absolute;
  right: 50px;
`;

const MeetingWidth = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonGroup = styled.div`
  border-top: 1px solid #ededed;
`;

/**
 * User ScheduleDetails Page
 */
const ScheduleDetails = ({ match }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [{ auth }] = useStore();
  const { id } = match.params;
  const [schedule, setSchedule] = useState(null);
  const { data, loading, error } = useQuery(GET_SCHEDULE, {
    variables: { scheduleId: id },
  });

  const [deleteSchedule] = useMutation(DELETE_SCHEDULE, {});

  const [confirmSchedule] = useMutation(CONFIRM_SCHEDULE, {
    refetchQueries: [
      {
        query: GET_SCHEDULE,
        variables: {
          scheduleId: id,
        },
      },
    ],
  });

  const [updateSchedule] = useMutation(UPDATE_SCHEDULE, {
    refetchQueries: [
      {
        query: GET_SCHEDULE,
        variables: {
          scheduleId: id,
        },
      },
    ],
  });

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  const handleWhen = (d) => {
    if (!d) return;
    setSchedule(d);
  };

  const handleDeleteSchedule = async (scheduleId) => {
    await deleteSchedule({
      variables: {
        input: {
          id: scheduleId,
          authUserId: auth.user.id,
        },
      },
    });
    toast('👏 Deleted! ')
  };

  const handleConfirmSchedule = async (scheduleId) => {
    await confirmSchedule({
      variables: {
        input: {
          id: scheduleId,
          authUserId: auth.user.id,
        },
      },
    });
    toast('👏 Confirmed! ')
  };

  const handleUpdateSchedule = async (scheduleId) => {
    await updateSchedule({
      variables: {
        input: {
          id: scheduleId,
          schedule,
        },
      },
    });
    toast('👏 Updated! ')
  };

  const calendarContact =
    auth.user.id === data?.getSchedule?.receiver.id ? data?.getSchedule?.sender : data?.getSchedule?.receiver;

  return (
    <Content>
      <Root maxWidth="md">
        {data && data.getSchedule && data.getSchedule.sender && data.getSchedule.receiver  && (
          <Wrapper>
            <WaintingForConfirmation>
              {auth.user.id === data.getSchedule.sender.id && !data.getSchedule.confirmed && (
                <div style={{ fontSize: '10px' }}>🔴 Waiting for confirmation</div>
              )}
            </WaintingForConfirmation>

          
           

            

            
            <Spacing bottom={'xs'} />
            <MeetingWidth>
              <div>
                <A to={generatePath(Routes.USER_PROFILE, { username: calendarContact.username })}>
                  <H2>
                    {t('meetingwith')} {calendarContact.fullName} {formatDistance(data.getSchedule.schedule, new Date(), { addSuffix: true }) }
                  </H2>
                </A>
              </div>
              <div></div>
            </MeetingWidth>

            <div></div>
            <Spacing top="lg">
              {
                !!data?.getSchedule?.id && <MettingButton
                to={generatePath(Routes.ROOM, {
                  roomId: data.getSchedule.id.trim(),
                  userId: (auth.user.id === data?.getSchedule?.receiver.id) ?
                    data?.getSchedule?.sender.id
                    : data?.getSchedule?.receiver.id
                })}
              >
                <Spacing right={'xs'}>
                  <CameraIcon color={'white'} />
                </Spacing>
                {t('joinmeeting')}
              </MettingButton>
              }
         
              <Spacing bottom={'md'} />
              {data?.getSchedule && (
                <ButtonGroup>
                  {schedule && <UpdateBtn onClick={() => handleUpdateSchedule(data.getSchedule.id)}>{t('update')}</UpdateBtn>}
                  {auth.user.id !== data.getSchedule.sender.id && !data.getSchedule.confirmed && (
                    <Accept onClick={() => handleConfirmSchedule(data.getSchedule.id)}>{t('accept')}</Accept>
                  )}


                  {
                    <Button
                      secondary
                      onClick={() => {
                        handleDeleteSchedule(data.getSchedule.id);
                        history.push('/schedules');
                      }}
                    >
                      ❌ {t('cancelmeeting')}
                    </Button>

                  }

                  
<Button
                      secondary
                      onClick={() => {history.push(generatePath(Routes.MESSAGES, { userId: calendarContact.id }))}
                      }
                    >
                  💬 { calendarContact.fullName }
                  </Button>
                  
                  <DatePicker
                       minDate={new Date("2021", "10", "10")}
                       maxDate={new Date("2021", "10", "11")}
              selected={schedule || data.getSchedule.schedule}
              showTimeSelect
              onChange={(date) => handleWhen(date)}
              customInput={
                <Button secondary>
                  {data.getSchedule.schedule
                    ? '✏️     ' + format(data.getSchedule.schedule, "yyyy-MM-dd @ HH:mm")
                    : 'Choose a Date and time'}
                </Button>
              }
            />
                </ButtonGroup>
              )}
               <Spacing bottom={'xl'} />
            </Spacing>
           
            <AddToCalendar             startTime={data.getSchedule.schedule}
            title={`Meeting with ${auth.user.id === data.getSchedule.sender.id ? data.getSchedule.receiver.fullName : data.getSchedule.sender.fullName}`} />
          </Wrapper>
        ) 
          
        }

{loading && <Skeleton height={300} count={2} />}


        {
          !loading && !data?.getSchedule?.sender?.id && <Empty text="This appointment is not found or has been cancelled" />
        }
      </Root>
    </Content>
  );
};

ScheduleDetails.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(ScheduleDetails);
