import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next';

import Search from 'components/Search/Search';

import { GET_SCHEDULES, CREATE_SCHEDULE } from 'graphql/schedule';
import { useStore } from 'store';
import { useMutation } from '@apollo/client';

import { useNotifications } from 'hooks/useNotifications';

import { NotificationType } from 'constants/NotificationType';

import 'react-datepicker/dist/react-datepicker.css';
import './datePickerCustomStyling.css';



const Button = styled.button`
 height: 40px;
 min-width: 100px;
 cursor: pointer;
 outline: none;
 font-size: ${(p) => p.theme.font.size.xxs};
 font-weight: ${(p) => p.theme.font.weight.bold};
 transition: background-color 0.2s, border-color 0.1s;
 border-radius: ${(p) => p.theme.radius.sm};
 color: ${(p) => !p.light && p.theme.colors.white};
 padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
 border: ${(p) => (p.light ? `1px solid ${p.theme.colors.border.main}` : '0')};
 background-color: ${(p) => (p.light ? 'transparent' : p.theme.colors.primary.main)};

 &:hover {
   border-color: ${(p) => p.theme.colors.border.dark};
 }
`;


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: white;
  z-index: ${(p) => p.theme.zIndex.xl};
  padding: ${(p) => p.theme.spacing.sm};
  border-radius:${(p) => p.theme.radius.lg} ;
  >div{
    margin-right: 10px;
  }
  

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    flex-direction: column;
    >div{
    margin-bottom: 10px;
  }
  }
`;



const AddSchedule = ({ onSubmit, user }) => {
  const { t } = useTranslation()
  const notification = useNotifications()
  const [{ auth }] = useStore();

  const [schedule, setSchedule] = useState(new Date());
  const [receiver, setReceiver] = useState(user);

  const [createSchedule, { loading /*error, data*/ }] = useMutation(CREATE_SCHEDULE, {
    refetchQueries: [{ query: GET_SCHEDULES, variables: { authUserId: auth.user.id } }],
  });

  const handleCreateSchedule = async () => {
    try {
      const { data } = await createSchedule({
        variables: {
          input: {
            sender: auth.user.id,
            receiver: receiver?.id,
            schedule: schedule
          },
        },
      })

      toast('👏 Request is sent!')

      await notification.create({
        user: data.createSchedule.receiver,
        scheduleId: data.createSchedule.id,
        notificationType: NotificationType.SCHEDULE,
        notificationTypeId: data.createSchedule ? data.createSchedule.id : null,
      
      });

    } catch (error) {
      console.error(error)
      toast.error('A problem occurs - #112174')
    }
  };

  const handleWhen = (d) => {
    if (!d) return;
    setSchedule(d)
  };

  useEffect(() => {
    if (false) {
      /**
       *  await notification.toggle({
        user,
        hasDone: isFollowing,
        notificationType: NotificationType.SCHEDULE,
        notificationTypeId: data.createFollow ? data.createFollow.id : null,
      });
       */
    }

  },[])

  return (
  
     
        <Wrapper>
          <div>
        <DatePicker
          minDate={new Date()}
              selected={schedule}
              showTimeSelect
              onChange={(date) => handleWhen(date)}
              customInput={
                <Button color={'black'} type="button" light >
                  {schedule ? format(schedule, 'MMM/dd @ HH:mm') : t('chooseDateTime')}
                </Button>
              }
        />
    
      <p style={{fontSize: '11px'}}>Time zone {window.Intl.DateTimeFormat().resolvedOptions().timeZone}</p>
          </div>

          <div>
        <Search onClick={(e) => setReceiver(e)} value={receiver?.fullName} placeholder={ t('chooseParticipant')} />
          </div>

          <div>
           
        <Button type="button" onClick={() => {
          handleCreateSchedule()
          onSubmit()
        }}>
            {
              loading ? "Loading..." : t('askForAppointement')
            }
             
            </Button>
          </div>
        </Wrapper>
    
  );
};

export default AddSchedule;
