import React from 'react';
import theme from 'theme';

/**
 * Schedule icon
 *
 * @param {string} width
 * @param {string} color
 */
export const ScheduleIcon = ({ width, color }) => {
  const DEFAULT_WIDTH = '22';
  const DEFAULT_COLOR = theme.colors.text.secondary;

  return (

<svg  width={width || DEFAULT_WIDTH} height={width || DEFAULT_WIDTH} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19 22H5C3.89543 22 3 21.1046 3 20V6C3 4.89543 3.89543 4 5 4H7V2H9V4H15V2H17V4H19C20.1046 4 21 4.89543 21 6V20C21 21.1046 20.1046 22 19 22ZM5 10V20H19V10H5ZM5 6V8H19V6H5ZM13 18H7V12H13V18Z"  fill={theme.colors[color] || DEFAULT_COLOR}/>
</svg>


  );
};
