import { gql } from '@apollo/client';

/**
 * Creates a schedule
 */
export const GET_ROOMS = gql`
  query($authUserId: ID!) {
    getRooms(authUserId: $authUserId) {
    rooms{
      room
      pair {
        id
        fullName
      }
      createdAt
    }
    count
     
    }
  }
`;