import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

const LottieSocial = () => (
    <Player
      autoplay
      bounce
      loop
      src="https://assets4.lottiefiles.com/packages/lf20_ffkzpglj.json"
      style={{ height: '300px', width: '300px' }}
    />
    
);

export default LottieSocial;
