import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from 'components/icons';
import { Spacing } from 'components/Layout';

const AddSchedule = styled.div`
  padding: ${(p) => p.theme.spacing.sm};
  background: linear-gradient(90deg, rgba(6,111,197,1) 0%, rgba(0,163,255,1) 100%);
  color: ${(p) => p.theme.colors.white};
  border-radius:  ${(p) => p.theme.colors.primary.light};;

  justify-content: flex-start;
  align-items: center;
  display: flex;
  height: 50px;
  cursor: pointer;
 
  &:active{
    background-color: ${(p) => p.theme.colors.black};
  }
`;

const AddScheduleTitle = styled.h4`
  color: ${(p) => p.theme.colors.white};
  font-weight: 500;
  font-size: 15px;
`;

/**
 * Schedule list
 */

const AddScheduleBtn = ({ openModal }) => {
  const { t } = useTranslation()
  return (
   
          <AddSchedule onClick={ ()=>openModal()}>
              <PlusIcon width={'40px'} color={ 'white'}/>
        <Spacing inline right="sm" />
      <AddScheduleTitle>{ t('schedulenew')}</AddScheduleTitle>
      </AddSchedule>
     
  );
};

export default AddScheduleBtn;
