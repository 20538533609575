//https://github.com/jasonsalzman/react-add-to-calendar/blob/master/src/helpers/index.js
import { add } from 'date-fns';
import SiteInfo from 'constants/SiteInfo.json'

function getRandomKey() {
  let n = Math.floor(Math.random() * 999999999999).toString();
  return new Date().getTime().toString() + '_' + n;
}

function formatTime(date) {
  return new Date(date).toISOString().replace(/[.,#!$%&;:{}=\-_`~()]/g, '');
}

/**
 * buildUrl()
 * @param {*} event {startTime, endTime, location, title, description}
 * @returns
 */
export default function buildUrl(
  {
    startTime,
    endTime = add(startTime, { minutes: 30 }),
    location = 'Tangier Tourism Market',
    title,
    description = SiteInfo.url,
  },
  type
) {
  let calendarData = '';

  switch (type) {
    case 'google':
      calendarData = 'https://calendar.google.com/calendar/render';
      calendarData += '?action=TEMPLATE';
      calendarData += '&dates=' + formatTime(startTime);
      calendarData += '/' + formatTime(endTime);
      calendarData += '&location=' + encodeURIComponent(location);
      calendarData += '&text=' + encodeURIComponent(title);
      calendarData += '&details=' + encodeURIComponent(description);
      break;
    case 'outlookcom':
      calendarData = 'https://outlook.live.com/owa/?rru=addevent';
      calendarData += '&startdt=' + formatTime(startTime);
      calendarData += '&enddt=' + formatTime(endTime);
      calendarData += '&subject=' + encodeURIComponent(title);
      calendarData += '&location=' + encodeURIComponent(location);
      calendarData += '&body=' + encodeURIComponent(description);
      calendarData += '&allday=false';
      calendarData += '&uid=' + getRandomKey();
      calendarData += '&path=/calendar/view/Month';
      break;

    default:
      calendarData = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        'URL:' + document.URL,
        'DTSTART:' + formatTime(startTime),
        'DTEND:' + formatTime(endTime),
        'SUMMARY:' + title,
        'DESCRIPTION:' + description,
        'LOCATION:' + location,
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n');
      break;
  }

  if (type !== 'google') {
    calendarData = encodeURI('data:text/calendar;charset=utf8,' + calendarData);
  }

  return calendarData;
}
